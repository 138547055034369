import React, { ChangeEvent, useCallback, useMemo, useState } from "react";
import { Text } from "../text/Text";
import "./Dropdown.scss";

type InputType = "text" | "number" | "date";

interface DropdownOption {
  value: string;
  label: string;
}

interface DropdownProps {
  title?: string;
  options?: DropdownOption[];
  selectedValue: string | number | undefined;
  placeholder?: string;
  onSelectChange: (value: string | undefined) => void;
  type?: InputType;
  classname?: string;
  disabled?: boolean;
}

export const Dropdown = ({
  title,
  options,
  selectedValue,
  placeholder,
  onSelectChange,
  type,
  classname,
  disabled,
}: DropdownProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const handleSelectChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      onSelectChange(e.target.value === "" ? undefined : e.target.value);
    },
    [onSelectChange]
  );

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const inputType = useMemo(() => {
    switch (type) {
      case "number":
        return "number";
      case "date":
        return "date";
      default:
        return "text";
    }
  }, [type]);

  return (
    <div className="details-dropdown-container">
      <div className="dropdown-title">
        {title && <Text text={title} size="xxxs" fontWeight={600} />}
      </div>
      {options ? (
        <select
          className={`dropdown-input ${classname}`}
          value={selectedValue || ""}
          onChange={handleSelectChange}
          disabled={disabled}
        >
          <option value="" disabled hidden>
            {placeholder}
          </option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      ) : (
        <input
          type={inputType}
          className="dropdown-input"
          placeholder={!isFocused ? placeholder : ""}
          value={selectedValue || ""}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={(e) => onSelectChange(e.target.value)}
        />
      )}
    </div>
  );
};
