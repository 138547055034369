import { openDB, DBSchema } from "idb";

interface MyDB extends DBSchema {
  responses: {
    key: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
  };
}

const dbPromise = openDB<MyDB>("av-task-route", 1, {
  upgrade(db) {
    db.createObjectStore("responses");
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function setResponse(key: string | undefined, value: any) {
  if (!key) return;
  const db = await dbPromise;
  await db.put("responses", value, key);
}

export async function getResponse(key: string) {
  const db = await dbPromise;
  return db.get("responses", key);
}
