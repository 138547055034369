import Icon from "@avinet/adaptive-ui-core/ui/Icon";
import React, { ReactNode, useCallback, useContext, useMemo } from "react";
import { NgirLogoIcon } from "../../assets/icons/NgirLogoIcon";
import { Text } from "../text/Text";
import "./PageLayout.scss";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/user-context/UserContext";
import { mapUrl, ordersOverviewUrl, settingsUrl } from "../../constants/Routes";
import { t } from "i18next";
import LOCAL_STORAGE from "../../constants/LocalStorage";
import { useOrder } from "../../context/order-context/OrderContext";
import { currentApi } from "../../utils/customer-config";
import { useMobileView } from "../../context/mobile-view-context/MobileViewContext";

interface PageLayoutProps {
  children: ReactNode;
  orderBtnActive?: boolean;
}

const PageLayout = ({ children }: PageLayoutProps) => {
  const { currentOrder } = useOrder();
  const { isMobileView } = useMobileView();
  const { logoutUser } = useContext(UserContext);

  const handleLogout = async (e: React.FormEvent) => {
    e.preventDefault();
    logoutUser();
  };

  const navigate = useNavigate();

  const currentSelectedFilter = useMemo(() => {
    const selectedFilter = localStorage.getItem(LOCAL_STORAGE.SELECTED_FILTER);

    if (selectedFilter) {
      return selectedFilter;
    }

    return null;
  }, []);

  const onSelectOrdersOverview = useCallback(() => {
    if (currentOrder)
      navigate(
        `${ordersOverviewUrl}/${currentOrder.id}/${currentSelectedFilter}`
      );
    else navigate(ordersOverviewUrl);
  }, [currentOrder, currentSelectedFilter, navigate]);

  const isButtonSelected = useCallback((url: string) => {
    const baseUrl = location.pathname.split("/")[1];
    return baseUrl === url.split("/")[1] ? "selected-btn" : "";
  }, []);

  const { customer } = currentApi();

  const renderIcon = () => {
    switch (customer) {
      case "NGIR":
        return <NgirLogoIcon />;
      default:
        return <img src="av_hvit.svg" alt="Asplan Viak" />;
    }
  };

  return (
    <div className="page-layout">
      <div className="sidebar">
        <div className="logo" onClick={() => navigate(ordersOverviewUrl)}>
          {renderIcon()}
        </div>
        <div className="menu">
          <div
            className={`menu-button ${isButtonSelected(ordersOverviewUrl)}`}
            onClick={onSelectOrdersOverview}
          >
            <Icon name="clipboardTaskList" />
            {!isMobileView && <Text text={t("common.order")} size="xxs" />}
          </div>
          {!isMobileView && (
            <div
              className={`menu-button ${isButtonSelected(mapUrl)}`}
              onClick={() => navigate(mapUrl)}
            >
              <Icon name="database" />
              <Text text={t("common.otherContent")} size="xxs" />
            </div>
          )}
          <div
            className={`menu-button ${isButtonSelected(settingsUrl)}`}
            onClick={() => navigate(settingsUrl)}
          >
            <Icon name="settings" />
            {!isMobileView && <Text text={t("common.settings")} size="xxs" />}
          </div>
          <div className="logout" onClick={handleLogout}>
            <Icon name="signOut" />
            {!isMobileView && <Text text={t("common.logout")} size="xxs" />}
          </div>
        </div>
      </div>
      <div className={`content`}>{children}</div>
    </div>
  );
};

export default PageLayout;
