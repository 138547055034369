import { t } from "i18next";
import React, {
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import { NgirLogoIcon } from "../../assets/icons/NgirLogoIcon";
import { Text } from "../../components/text/Text";
import { VerifyCodeInput } from "../../components/verify-code-input/VerifyCodeInput";
import LOCAL_STORAGE from "../../constants/LocalStorage";
import { createNewPasswordUrl } from "../../constants/Routes";
import { UserContext } from "../../context/user-context/UserContext";
import { getFromStore } from "../../utils/local-storage";

export const EnterCode = () => {
  const { verifyBegin, verify } = useContext(UserContext);
  const navigate = useNavigate();
  const [resetPasswordEmail, setResetPasswordEmail] = useState<string>();
  const [verifyCode, setVerifyCode] = useState("");
  const [verifyCodeError, setVerifyCodeError] = useState(false);
  const verifyCodeLength = 6;

  useEffect(() => {
    const fetchResetEmail = async () => {
      try {
        const email = await getFromStore<string>(
          LOCAL_STORAGE.RESET_PASSWORD_EMAIL
        );
        setResetPasswordEmail(email ?? "");
      } catch (error) {
        console.error("Error fetching reset email:", error);
      }
    };

    fetchResetEmail();
  }, []);

  const handleSubmitForm = async (event: SyntheticEvent) => {
    event.preventDefault();
    try {
      await verify(verifyCode, resetPasswordEmail);
      const token = await getFromStore<string>(
        LOCAL_STORAGE.RESET_PASSWORD_TOKEN
      );
      if (token !== undefined && token !== null) {
        setVerifyCodeError(false);
        navigate(createNewPasswordUrl);
      } else {
        setVerifyCodeError(true);
      }
    } catch (error) {
      console.error("Verification error:", error);
      setVerifyCodeError(true);
    }
  };

  const handleResendCode = useCallback(() => {
    if (resetPasswordEmail !== undefined) {
      verifyBegin(resetPasswordEmail).catch(() => {
        console.error(Error);
      });
    }
  }, [resetPasswordEmail, verifyBegin]);

  return (
    <div className="reset-password">
      <div className="reset-password__form">
        <form onSubmit={handleSubmitForm}>
          <NgirLogoIcon />
          <Text
            className="reset-password__form__header"
            text={t("pages.enterCode.title")}
            color="dark"
            size="medium"
            fontWeight={600}
          />
          <Text
            className="reset-password__form__desc"
            text={t("pages.enterCode.desc")}
            color="dark-contrast"
            size="small"
            fontWeight={400}
          />
          <Text
            className="reset-password__form__email"
            text={resetPasswordEmail ?? ""}
            color="dark"
            size="small"
            fontWeight={600}
          />
          <div className="reset-password__form__input">
            <VerifyCodeInput
              codeLength={verifyCodeLength}
              error={verifyCodeError}
              verifyCodeValue={verifyCode}
              setVerifyCodeValue={setVerifyCode}
              resentCode={() => handleResendCode()}
              onClickVerify={() => handleSubmitForm}
            />
          </div>
        </form>
      </div>
    </div>
  );
};
