import React from "react";
import "./IconWithBadge.scss";

interface IconWithBadgeProps {
  icon: JSX.Element;
  count: number;
}

const IconWithBadge = ({ icon, count }: IconWithBadgeProps) => {
  return (
    <div className="icon-container">
      {icon}
      {count > 0 && <span className="badge">{count}</span>}
    </div>
  );
};

export default IconWithBadge;
