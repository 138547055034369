import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./OrderList.scss";
import { Icon } from "../../components/icon/Icon";
import useDataFetch from "../../hooks/useDataFetch";
import { DEFAULT_API_CONFIG } from "../../api/api-config";
import { IFilter, IOrder } from "../../utils/data-types";
import { useNavigate } from "react-router-dom";
import { ordersOverviewUrl } from "../../constants/Routes";
import DetailsFilter from "../../components/details-filter/DetailsFilter";
import { t } from "i18next";
import LOCAL_STORAGE from "../../constants/LocalStorage";
import { Text } from "../../components/text/Text";
import ProgressBar from "../../components/progress-bar/ProgressBar";
import { useOrder } from "../../context/order-context/OrderContext";
import { LoadingMask } from "../../components/loading/LoadingMask";
import { getResponse } from "../../utils/indexedDb";
import { STATUSES } from "../../constants/Constants";

const OrderList = () => {
  const { saveOrder } = useOrder();

  const [selectedOptions, setSelectedOptions] = useState<{
    [key: string]: string | number | undefined;
  }>({
    status: "",
    route: "",
    week: 0,
    year: 0,
    type: "",
    priority: "",
    responsible: "",
    owner: "",
  });

  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [
    shouldShowOwnerResponsiblePriorityFilter,
    setShouldShowOwnerResponsiblePriorityFilter,
  ] = useState<boolean>(false);

  const handleFilterOptionChange = useCallback(
    (key: string, value: string | number | undefined) => {
      setSelectedOptions((prevOptions) => ({
        ...prevOptions,
        [key]: value,
      }));
    },
    []
  );

  const filtersRequest = useMemo(() => {
    return {
      field_name: "order_main_filter",
    };
  }, []);

  const filtersApiUrl = useMemo(
    () => DEFAULT_API_CONFIG.url + "/diaryorder/codeV2/get",
    []
  );

  const [filters] = useDataFetch<IFilter[]>(
    filtersApiUrl,
    "POST",
    filtersRequest
  );

  useEffect(() => {
    if (navigator.onLine) {
      localStorage.setItem(
        LOCAL_STORAGE.DROPDOWN_FILTERS,
        JSON.stringify(filters)
      );
    }
  }, [filters]);

  const filtersToUse = useMemo<IFilter[]>(() => {
    if (filters) {
      return filters;
    }
    const savedFilters = localStorage.getItem(LOCAL_STORAGE.DROPDOWN_FILTERS);
    if (savedFilters && savedFilters !== "undefined") {
      return JSON.parse(savedFilters);
    }
    return [];
  }, [filters]);

  const [selectedFilter, setSelectedFilter] = useState<string>("");

  useEffect(() => {
    if (!localStorage.getItem(LOCAL_STORAGE.SELECTED_FILTER) && filters) {
      localStorage.setItem(LOCAL_STORAGE.SELECTED_FILTER, filters[0].code);
    }
    const savedFilter = localStorage.getItem(LOCAL_STORAGE.SELECTED_FILTER);
    if (savedFilter) {
      setSelectedFilter(savedFilter);
    } else if (filters && filters.length > 0) {
      setSelectedFilter(filters[0].code);
    }
  }, [filters]);

  const ordersApiUrl = useMemo(
    () => DEFAULT_API_CONFIG.url + "/diaryorder/tasks/getMine",
    []
  );

  const ordersRequest = useMemo(() => {
    return {
      mainfilter: selectedFilter,
      status: selectedOptions.status,
      route: selectedOptions.route,
      week: selectedOptions.week,
      year: selectedOptions.year,
      type: selectedOptions.type,
      priority: selectedOptions.priority,
      responsible: selectedOptions.responsible,
      owner: selectedOptions.owner,
    };
  }, [
    selectedFilter,
    selectedOptions.owner,
    selectedOptions.priority,
    selectedOptions.responsible,
    selectedOptions.route,
    selectedOptions.status,
    selectedOptions.type,
    selectedOptions.week,
    selectedOptions.year,
  ]);

  const [orders, loading] = useDataFetch<IOrder[]>(
    ordersApiUrl,
    "POST",
    ordersRequest,
    selectedFilter
  );

  const handleDropdownChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const value = event.target.value;
      setSelectedFilter(value);
      localStorage.setItem(LOCAL_STORAGE.SELECTED_FILTER, value);
    },
    []
  );

  useEffect(() => {
    const filter = filtersToUse?.find(
      (filter) => filter.code === selectedFilter
    );
    if (!(filter && filter.tag)) return;
    const tag = filter.tag;
    const tagAsJson = JSON.parse(tag ?? "{}");
    const isCrm = tagAsJson.find(
      (tag: { key: string; value: string }) =>
        tag.key === "crm" && tag.value === "true"
    );
    if (isCrm) {
      setShouldShowOwnerResponsiblePriorityFilter(true);
    } else {
      setShouldShowOwnerResponsiblePriorityFilter(false);
    }

    const isStartWeight = tagAsJson.find(
      (tag: { key: string }) => tag.key === "startweight"
    );
    if (isStartWeight) {
      localStorage.setItem(LOCAL_STORAGE.NEED_STARTWEIGHT, "true");
    } else {
      localStorage.setItem(LOCAL_STORAGE.NEED_STARTWEIGHT, "false");
    }
  }, [filtersToUse, selectedFilter]);

  const navigate = useNavigate();

  const handleNavigateToOrder = useCallback(
    (id: number) => {
      const order = orders?.find((order) => order.id === id);
      if (order) saveOrder(order);
      navigate(ordersOverviewUrl + "/" + id + "/" + selectedFilter);
    },
    [navigate, orders, saveOrder, selectedFilter]
  );

  const handleShowFilter = useCallback(() => {
    setShowFilter(!showFilter);
  }, [showFilter]);

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE.MAP_EXTENT, "[]");
  }, []);

  const getOrdersFromIndexedDB = useCallback(async () => {
    const cachedOrders = await getResponse(selectedFilter);
    if (cachedOrders) {
      return cachedOrders;
    }
    return [];
  }, [selectedFilter]);

  const [ordersToUse, setOrdersToUse] = useState<IOrder[] | undefined>();

  useEffect(() => {
    if (window.navigator.onLine) {
      setOrdersToUse(orders);
    } else {
      getOrdersFromIndexedDB().then((cachedOrders) =>
        setOrdersToUse(cachedOrders)
      );
    }
  }, [getOrdersFromIndexedDB, orders]);

  return (
    <>
      <div className="dropdown-container">
        <div className="gap-1">
          <Text
            className="filter-text"
            text={t("pages.orders.filter")}
            size="xxs"
          />
          <select onChange={handleDropdownChange} value={selectedFilter}>
            {filtersToUse && filtersToUse.length > 0 && (
              <option value={filtersToUse[0].code}>
                {filtersToUse[0].codedesc}
              </option>
            )}
            {filtersToUse &&
              filtersToUse.slice(1).map((filter) => (
                <option key={filter.code} value={filter.code}>
                  {filter.codedesc}
                </option>
              ))}
          </select>
          <button className="btn-light" onClick={handleShowFilter}>
            {showFilter ? <Icon name="dismiss" /> : <Icon name="filter" />}
          </button>
        </div>
      </div>
      {showFilter && (
        <DetailsFilter
          selectedOptions={selectedOptions}
          onOptionChange={handleFilterOptionChange}
          showOwnerResponsiblePriorityFilter={
            shouldShowOwnerResponsiblePriorityFilter
          }
        />
      )}
      <div className="table-container">
        <div className="header-row">
          <div className="header-cell status">{t("pages.orders.status")}</div>
          <div className="header-cell paperclip"></div>
          <div className="header-cell week">{t("pages.orders.week")}</div>
          <div className="header-cell progress">
            {t("pages.orders.progress")}
          </div>
          <div className="header-cell name">{t("pages.orders.name")}</div>
          <div className="header-cell type">{t("pages.orders.type")}</div>
          <div className="header-cell priority">
            {t("pages.orders.priority")}
          </div>
          <div className="header-cell responsible">
            {t("pages.orders.responsible")}
          </div>
          <div className="header-cell chevron"></div>
        </div>

        <div className={`table-content ${showFilter ? "small" : ""}`}>
          {loading ? (
            <LoadingMask />
          ) : (
            ordersToUse &&
            ordersToUse.map((order, index) => {
              const totalOrderObjects = order.orderobject.length;
              const completedOrderObjects = order.orderobject.filter(
                (obj) =>
                  obj.status === STATUSES.COMPLETED ||
                  obj.status === STATUSES.NOT_COMPLETED
              ).length;

              const progressPercentage =
                totalOrderObjects > 0
                  ? Math.round(
                      (completedOrderObjects / totalOrderObjects) * 100
                    )
                  : 0;

              return (
                <div
                  key={index}
                  className="table-row"
                  onClick={() => handleNavigateToOrder(order.id)}
                >
                  <div className="table-cell status">
                    <div className={`${order.status}`}>{order.status}</div>
                  </div>
                  <div className="table-cell paperclip">
                    {order.attachments > 0 && (
                      <Icon name="attach" className="paperclip" />
                    )}
                  </div>
                  <div className="table-cell week">{order.week}</div>
                  <div className="table-cell progress">
                    <div className="progress-bar">
                      <ProgressBar progress={progressPercentage} />
                    </div>
                    <div className="progress-text">
                      <Text
                        text={`${completedOrderObjects} av ${totalOrderObjects}`}
                        size="xxxs"
                      />
                    </div>
                  </div>
                  <div className="table-cell name">{order.name}</div>
                  <div className="table-cell type">{order.type}</div>
                  <div className="table-cell priority">{order.priority}</div>
                  <div className="table-cell responsible">
                    {order.responsible}
                  </div>
                  <div className="table-cell chevron">
                    <Icon name="chevron" />
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </>
  );
};

export default OrderList;
