import { MapView } from "@avinet/adaptive-ui-maps";
import Zoom from "@avinet/adaptive-ui-maps/control/Zoom";
import ZoomToExtent from "@avinet/adaptive-ui-maps/control/ZoomToExtent";
import XyzLayer from "@avinet/adaptive-ui-maps/layer/XyzLayer";
import VectorLayer from "@avinet/adaptive-ui-maps/vector/VectorLayer";
import VectorSource from "@avinet/adaptive-ui-maps/vector/source/VectorSource";
import Feature from "ol/Feature";
import Icon from "@avinet/adaptive-ui-core/ui/Icon";
import { Icon as OlIcon, Style, Circle, Fill, Stroke, Text } from "ol/style";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { useMapContext } from "@avinet/adaptive-ui-maps";
import Point from "ol/geom/Point";
import LineString from "ol/geom/LineString";
import { MapDestinationIcon } from "../../assets/icons/MapDestinationIcon";
import "./MapWrapper.scss";
import { SelectedMapDestinationIcon } from "../../assets/icons/SelectedMapDestination";
import { fromExtent as polygonFromExtent } from "ol/geom/Polygon";
import { MapBrowserEvent } from "ol";
import LOCAL_STORAGE from "../../constants/LocalStorage";
import ImageWmsLayer from "@avinet/adaptive-ui-maps/layer/ImageWmsLayer";
import MapLayerList from "../map-layer-list/MapLayerList";
import { map_layers } from "../../constants/MapLayers";
import { FeatureLike } from "ol/Feature";
import {
  DRAWING_OBJECT_TYPES,
  MAP_OPTIONS,
  STATUSES,
} from "../../constants/Constants";
import { IOrder, OrderObject } from "../../utils/data-types";
import { useDrawing } from "../../context/drawing-context/DrawingContext";

export const MapWrapper = ({
  featuresWKT1,
  featuresWKT2,
  selectedId,
  defaultExtent,
  selectedOption,
  onFeatureMapClick,
  sendExtentToParent,
  sendZoomToParent,
  sendPositionToParent,
  order,
}: {
  children: ReactNode;
  featuresWKT1?: Feature[];
  featuresWKT2?: Feature[];
  selectedId: number | null;
  defaultExtent: number[];
  selectedOption: string;
  onFeatureMapClick: (id: number | null) => void;
  sendExtentToParent?: (extent: number[]) => void;
  sendZoomToParent?: (zoom: number) => void;
  sendPositionToParent?: (position: Point) => void;
  order?: IOrder;
}) => {
  const [userPosition, setUserPosition] = useState<Point>(
    new Point([]) as Point
  );
  const [pointClicked, setPointClicked] = useState<Point>(
    new Point([]) as Point
  );
  const [lineCoordinates, setLineCoordinates] = useState<number[][]>([]);
  const [isPointSelected, setIsPointSelected] = useState<boolean>(false);
  const [isLineDrawn, setIsLineDrawn] = useState<boolean>();
  const [lineFeature, setLineFeature] = useState<Feature | null>(null);
  const [trackPosition, setTrackPosition] = useState<boolean>(false);
  const [extentState, setExtentState] = useState(defaultExtent);
  const [isShowingMapLayers, setIsShowingMapLayers] = useState<boolean>(false);
  const [selectedMapOption, setSelectedMapOption] = useState<string>();
  const { setCenterAndZoom, view, map } = useMapContext();
  const {
    isDrawMode,
    isMovingPoint,
    setHasDrawn,
    setDrawnObject,
    drawnObjectType,
  } = useDrawing();

  useEffect(() => {
    setIsPointSelected(
      pointClicked.getCoordinates().length > 0 && isDrawMode === true
    );
    setIsLineDrawn(lineCoordinates.length > 1);
    setHasDrawn((isPointSelected || isLineDrawn) ?? false);
  }, [
    setHasDrawn,
    isDrawMode,
    isLineDrawn,
    isPointSelected,
    lineCoordinates.length,
    pointClicked,
    isMovingPoint,
  ]);

  useEffect(() => {
    if (isPointSelected) {
      setDrawnObject(pointClicked);
    }
  }, [setDrawnObject, isPointSelected, pointClicked]);

  const toggleMapLayerList = useCallback(() => {
    setIsShowingMapLayers((prev) => !prev);
  }, []);

  const liftPosition = useCallback(
    (position: Point) => {
      sendPositionToParent?.(position);
    },
    [sendPositionToParent]
  );

  useEffect(() => {
    if (!userPosition) return;
    liftPosition(userPosition);
  }, [liftPosition, userPosition]);

  const liftZoom = useCallback(
    (zoom: number) => {
      sendZoomToParent?.(zoom);
    },
    [sendZoomToParent]
  );

  const zoom = view.getZoom();

  useEffect(() => {
    const handleMoveEnd = () => {
      const zoom = view.getZoom();
      if (!zoom) return;
      liftZoom(zoom);
    };

    map.on("moveend", handleMoveEnd);

    return () => {
      map.un("moveend", handleMoveEnd);
    };
  }, [liftZoom, map, selectedMapOption, view, zoom]);

  const liftExtent = useCallback(
    (extent: number[]) => {
      sendExtentToParent?.(extent);
    },
    [sendExtentToParent]
  );

  useEffect(() => {
    const handleMoveEnd = () => {
      const newExtent = view.calculateExtent();
      liftExtent(newExtent);
    };

    map.on("moveend", handleMoveEnd);

    return () => {
      map.un("moveend", handleMoveEnd);
    };
  }, [map, view, liftExtent, selectedMapOption]);

  const pinchRotate = map.getInteractions().getArray()[3];

  pinchRotate.setActive(false);

  useEffect(() => {
    const handleViewChange = () => {
      const extent = view.calculateExtent();
      setExtentState(extent);
    };

    // Attach event listener for view changes
    view.on("change:center", handleViewChange);

    // Cleanup event listener on component unmount
    return () => {
      view.un("change:center", handleViewChange);
    };
  }, [view]);

  useEffect(() => {
    localStorage.setItem(
      LOCAL_STORAGE.MAP_EXTENT,
      JSON.stringify(extentState) ?? "[]"
    );
  }, [extentState]);

  const handleMapClick = useCallback(
    (evt: MapBrowserEvent<UIEvent>) => {
      map.forEachFeatureAtPixel(evt.pixel, (feature) => {
        onFeatureMapClick(feature.getId() as number);
      });
    },
    [map, onFeatureMapClick]
  );

  const newPoint = useCallback((evt: MapBrowserEvent<UIEvent>) => {
    if (!evt.coordinate) return;
    const point = new Point(evt.coordinate);
    setPointClicked(point);
  }, []);

  const handleLineClick = useCallback(
    (evt: MapBrowserEvent<UIEvent>) => {
      if (!evt.coordinate) return;

      setLineCoordinates((prev) => {
        const newCoordinates = [...prev, evt.coordinate];

        // Create or update the LineString with the new coordinates
        const lineString = new LineString(newCoordinates);
        setDrawnObject(lineString);

        if (lineFeature) {
          // If a line feature already exists, update its geometry
          lineFeature.setGeometry(lineString);
        } else {
          // Create a new feature from the LineString and add it to the state
          const newLineFeature = new Feature({
            geometry: lineString,
          });
          setLineFeature(newLineFeature);
        }

        return newCoordinates;
      });
    },
    [setDrawnObject, lineFeature]
  );

  useEffect(() => {
    setPointClicked(new Point([]));
    setLineCoordinates([]);
    setLineFeature(null);
  }, [isDrawMode]);

  useEffect(() => {
    if (drawnObjectType === DRAWING_OBJECT_TYPES.POINT && isDrawMode) {
      map.on("click", newPoint);
      return () => {
        map.un("click", newPoint);
      };
    } else if (drawnObjectType === DRAWING_OBJECT_TYPES.LINE && isDrawMode) {
      map.on("click", handleLineClick);
      return () => {
        map.un("click", handleLineClick);
      };
    } else {
      map.on("click", handleMapClick);
      return () => {
        map.un("click", handleMapClick);
      };
    }
  }, [
    map,
    newPoint,
    handleMapClick,
    drawnObjectType,
    handleLineClick,
    isDrawMode,
  ]);

  const newPointFeature = useMemo(() => {
    return new Feature({
      geometry: pointClicked,
    });
  }, [pointClicked]);

  useEffect(() => {
    const e = defaultExtent ?? view.getProjection()?.getExtent();
    if (e) view.fitInternal(polygonFromExtent(e));
  }, [defaultExtent, view]);

  const [position, setPosition] = useState<GeolocationPosition | null>(null);

  const positionStyle = () => {
    return new Style({
      image: new Circle({
        radius: 6,
        fill: new Fill({
          color: "blue",
        }),
        stroke: new Stroke({
          color: "black",
          width: 1,
        }),
      }),
    });
  };

  const lineStyle = () => {
    return new Style({
      stroke: new Stroke({
        color: "blue",
        width: 3,
      }),
    });
  };

  const createSelectedStyle = (feature: FeatureLike): Style | undefined => {
    const name = feature.get("name");
    let nameWithoutCode = "";
    if (name) {
      const splitName = name.split(" ");
      splitName.shift();
      nameWithoutCode = splitName.join(" ");
    }
    if (feature.getGeometry()?.getType() === "Point") {
      return new Style({
        image: new OlIcon({
          anchor: [0.5, 1],
          src: `data:image/svg+xml;utf8,${encodeURIComponent(
            renderToStaticMarkup(<SelectedMapDestinationIcon />)
          )}`,
        }),
        text: new Text({
          font: "bold 12px sans-serif",
          rotation: 0,
          rotateWithView: false,
          scale: 1,
          text: nameWithoutCode,
          offsetY: -40,
        }),
      });
    } else if (feature.getGeometry()?.getType() === "LineString") {
      return new Style({
        stroke: new Stroke({
          color: "black",
          width: 3,
        }),
      });
    }
  };

  useEffect(() => {
    const watchId = navigator.geolocation.watchPosition(
      (pos) => setPosition(pos),
      (err) => console.error(err),
      {
        enableHighAccuracy: true,
        maximumAge: 5000,
      }
    );

    return () => navigator.geolocation.clearWatch(watchId);
  }, []);

  useEffect(() => {
    const coords = [position?.coords.longitude, position?.coords.latitude];
    if (coords) {
      const point = new Point(coords as [number, number]);
      point.transform("EPSG:4326", "EPSG:3857");
      setUserPosition(point);
    }
  }, [position]);

  const positionFeature = useMemo(() => {
    return new Feature({
      geometry: userPosition,
    });
  }, [userPosition]);

  const selectedFeatureWKT1 = useMemo(
    () => featuresWKT1?.find((feature) => feature.getId() === selectedId),
    [featuresWKT1, selectedId]
  );

  const selectedFeatureWKT2 = useMemo(
    () => featuresWKT2?.find((feature) => feature.getId() === selectedId),
    [featuresWKT2, selectedId]
  );

  const createCustomStyle = (feature: FeatureLike): Style | undefined => {
    const id = feature.getId();

    const orderObject = order?.orderobject.find(
      (o: OrderObject) => o.id === id
    );
    if (feature.getGeometry()?.getType() === "Point") {
      const status = orderObject?.status;
      switch (status) {
        case STATUSES.COMPLETED:
          return new Style({
            image: new OlIcon({
              anchor: [0.5, 1],
              src: `data:image/svg+xml;utf8,${encodeURIComponent(
                renderToStaticMarkup(<MapDestinationIcon fill="#079455" />)
              )}`,
            }),
          });
        case STATUSES.NOT_COMPLETED:
          return new Style({
            image: new OlIcon({
              anchor: [0.5, 1],
              src: `data:image/svg+xml;utf8,${encodeURIComponent(
                renderToStaticMarkup(<MapDestinationIcon fill="#f79009" />)
              )}`,
            }),
          });
        case STATUSES.ORDERED:
          return new Style({
            image: new OlIcon({
              anchor: [0.5, 1],
              src: `data:image/svg+xml;utf8,${encodeURIComponent(
                renderToStaticMarkup(<MapDestinationIcon fill="#155eef" />)
              )}`,
            }),
          });
        default:
          return new Style({
            image: new OlIcon({
              anchor: [0.5, 1],
              src: `data:image/svg+xml;utf8,${encodeURIComponent(
                renderToStaticMarkup(<MapDestinationIcon fill="#5157BD" />)
              )}`,
            }),
          });
      }
    } else if (feature.getGeometry()?.getType() === "LineString") {
      return new Style({
        stroke: new Stroke({
          color: "blue",
          width: 3,
        }),
      });
    }
  };

  useEffect(() => {
    setSelectedMapOption(selectedOption);
  }, [selectedOption]);

  useEffect(() => {
    if (
      (selectedFeatureWKT1 || selectedFeatureWKT2) &&
      selectedMapOption === MAP_OPTIONS.ZOOM_TO
    ) {
      setTrackPosition(false);
      if (selectedFeatureWKT2?.getGeometry()?.getType() === "LineString") {
        const geometry = selectedFeatureWKT2?.getGeometry() as LineString;
        const coords = geometry.getCoordinates();
        if (coords) {
          // fit the view to the extent of the line
          const line = new LineString(coords);
          const extent = line.getExtent();
          view.fit(extent, { padding: [100, 100, 100, 100] });
        }
      } else {
        const geometry = selectedFeatureWKT1?.getGeometry() as Point;
        const coords = geometry.getCoordinates();
        if (coords) {
          setCenterAndZoom(coords, 17);
        }
      }
    }
  }, [
    setCenterAndZoom,
    view,
    selectedMapOption,
    selectedFeatureWKT1,
    selectedFeatureWKT2,
  ]);

  const handleSetTrackPosition = useCallback(() => {
    setTrackPosition((prev) => !prev);
  }, []);

  useEffect(() => {
    if (trackPosition) setCenterAndZoom(userPosition.getCoordinates());
  }, [trackPosition, setCenterAndZoom, userPosition]);

  const [checkedLayers, setCheckedLayers] = useState<Set<string>>(
    localStorage.getItem(LOCAL_STORAGE.MAP_LAYERS_WITH_URI)
      ? new Set(
          JSON.parse(
            localStorage.getItem(LOCAL_STORAGE.CHECKED_MAP_LAYERS) ?? "[]"
          )
        )
      : new Set()
  );

  const handleCheckboxChange = useCallback((layerName: string | null) => {
    if (layerName === null) {
      setCheckedLayers(new Set());
      return;
    }
    setCheckedLayers((prev) => {
      const newCheckedLayers = new Set(prev);
      if (newCheckedLayers.has(layerName)) {
        newCheckedLayers.delete(layerName);
      } else {
        newCheckedLayers.add(layerName);
      }
      return newCheckedLayers;
    });
  }, []);

  useEffect(() => {
    localStorage.setItem(
      LOCAL_STORAGE.CHECKED_MAP_LAYERS,
      JSON.stringify(Array.from(checkedLayers))
    );
  }, [checkedLayers]);

  const activeLayers = useMemo(() => {
    return JSON.parse(
      localStorage.getItem(LOCAL_STORAGE.MAP_LAYERS_WITH_URI) ?? "[]"
    );
  }, []);

  // A comma-separated string of checked layers' names
  const checkedLayersList = useMemo(
    () => Array.from(checkedLayers).join(","),
    [checkedLayers]
  );

  const uri = useMemo<string>(
    () => activeLayers?.[0]?.uri ?? "",
    [activeLayers]
  );

  return (
    <>
      <MapView>
        <button
          className={`btn position ${trackPosition ? "active" : ""}`}
          onClick={handleSetTrackPosition}
        >
          <Icon name="myLocation" />
        </button>
        <button
          className={`btn layers ${isShowingMapLayers ? "active" : ""}`}
          onClick={toggleMapLayerList}
        >
          <Icon name="mapLayer" />
        </button>
        <Zoom />
        <ZoomToExtent extent={defaultExtent} />
      </MapView>
      {isShowingMapLayers && (
        <MapLayerList
          checkedLayers={checkedLayers}
          onCheckboxChange={handleCheckboxChange}
          showingMapLayers={isShowingMapLayers}
          onToggleMapLayerList={toggleMapLayerList}
        />
      )}
      <XyzLayer
        url={
          localStorage.getItem(LOCAL_STORAGE.SELECTED_MAP_URL) ??
          map_layers[0].value
        }
        zIndex={1}
      />

      {checkedLayersList && uri && (
        <ImageWmsLayer url={uri} layers={checkedLayersList} zIndex={10} />
      )}
      <VectorLayer zIndex={21} style={createCustomStyle} maxZoom={12}>
        <VectorSource features={featuresWKT1 ?? []} />
      </VectorLayer>
      <VectorLayer zIndex={21} style={createCustomStyle} minZoom={12}>
        <VectorSource features={featuresWKT2 ?? []} />
      </VectorLayer>
      <VectorLayer zIndex={22} style={createSelectedStyle} maxZoom={12}>
        <VectorSource
          features={selectedFeatureWKT1 ? [selectedFeatureWKT1] : []}
        />
      </VectorLayer>
      <VectorLayer zIndex={22} style={createSelectedStyle} minZoom={12}>
        <VectorSource
          features={selectedFeatureWKT2 ? [selectedFeatureWKT2] : []}
        />
      </VectorLayer>
      <VectorLayer zIndex={23} style={positionStyle}>
        <VectorSource features={positionFeature ? [positionFeature] : []} />
      </VectorLayer>
      {isDrawMode && (
        <VectorLayer zIndex={24} style={createSelectedStyle}>
          <VectorSource features={newPointFeature ? [newPointFeature] : []} />
        </VectorLayer>
      )}
      {isDrawMode && (
        <VectorLayer zIndex={25} style={lineStyle}>
          <VectorSource features={lineFeature ? [lineFeature] : []} />
        </VectorLayer>
      )}
    </>
  );
};
