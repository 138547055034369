import React from "react";
import "./AttachmentModalContent.scss";
import Attachments from "../attachments/Attachments";
import { t } from "i18next";

interface AttachmentModalContentProps {
  link_uuid: string;
  onClose: () => void;
}

export const AttachmentModalContent = ({
  link_uuid,
  onClose,
}: AttachmentModalContentProps) => {
  return (
    <div className="attachment-modal">
      <div className="attachment-modal-body">
        <Attachments link_uuid={link_uuid} />
      </div>
      <div className="attachment-modal-footer">
        <button className="btn" onClick={onClose}>
          {t("common.close")}
        </button>
      </div>
    </div>
  );
};
