import React, { useCallback, useEffect, useRef, useState } from "react";
import "./MapLayerList.scss";
import Search from "../search/Search";
import { t } from "i18next";
import LOCAL_STORAGE from "../../constants/LocalStorage";
import { IWmsLayer } from "../../utils/data-types";

export const MapLayerList = ({
  checkedLayers,
  onCheckboxChange,
  onToggleMapLayerList,
  showingMapLayers,
}: {
  checkedLayers: Set<string>;
  onCheckboxChange: (layerName: string | null) => void;
  onToggleMapLayerList: () => void;
  showingMapLayers: boolean;
}) => {
  const [activeLayers, setActiveLayers] = useState<IWmsLayer[]>([]);
  const [filteredLayers, setFilteredLayers] = useState<IWmsLayer[]>([]);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const layersWithUri = localStorage.getItem(
      LOCAL_STORAGE.MAP_LAYERS_WITH_URI
    );
    const parsedLayers = layersWithUri ? JSON.parse(layersWithUri) : [];
    setFilteredLayers(parsedLayers);
    setActiveLayers(parsedLayers);
  }, []);

  const handleSearch = useCallback((results: IWmsLayer[]) => {
    setFilteredLayers(results);
  }, []);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      const target = event.target as Node;
      const isToggleButton = (target as Element).closest(".btn.layers");
      if (ref.current && !ref.current.contains(target) && !isToggleButton) {
        onToggleMapLayerList();
      }
    },
    [onToggleMapLayerList]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [handleClickOutside]);

  if (!showingMapLayers) {
    return null;
  }

  return (
    <div
      ref={ref}
      className={`map-layer-list ${checkedLayers.size > 0 ? "tall" : ""}`}
    >
      {checkedLayers.size > 0 && (
        <div className="map-layer-list--item uncheck">
          <label htmlFor="turnOffAll">
            <input
              type="checkbox"
              id="turnOffAll"
              checked
              onChange={() => onCheckboxChange(null)}
            />
            {t("pages.map.turnOffAllLayers")}
          </label>
        </div>
      )}
      <div className="map-layer-list--content">
        {filteredLayers?.map((layer) => (
          <div key={layer.name} className="map-layer-list--item">
            <label htmlFor={layer.name}>
              <input
                type="checkbox"
                id={layer.name}
                checked={checkedLayers.has(layer.layerName)}
                onChange={() => onCheckboxChange(layer.layerName)}
              />
              {layer.name}
            </label>
          </div>
        ))}
      </div>
      <Search
        data={activeLayers}
        onSearch={handleSearch}
        placeholder={t("pages.map.searchPlaceholder")}
      />
    </div>
  );
};

export default MapLayerList;
