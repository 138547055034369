import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { NgirLogoIcon } from "../../assets/icons/NgirLogoIcon";
import { Text } from "../../components/text/Text";
import LOCAL_STORAGE from "../../constants/LocalStorage";
import { baseUrl } from "../../constants/Routes";
import { UserContext } from "../../context/user-context/UserContext";
import { getFromStore } from "../../utils/local-storage";
import { Icon } from "../../components/icon/Icon";

export const CreateNewPassword: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [resetPasswordEmail, setResetPasswordEmail] = useState<string>();

  const { resetPassword } = useContext(UserContext);

  const [formSent, setFormSent] = useState(false);

  useEffect(() => {
    const fetchResetEmail = async () => {
      try {
        const email = await getFromStore<string>(
          LOCAL_STORAGE.RESET_PASSWORD_EMAIL
        );
        setResetPasswordEmail(email ?? "");
      } catch (error) {
        console.error("Error fetching reset email:", error);
      }
    };
    fetchResetEmail();
  }, []);

  const handleSubmitForm = useCallback(() => {
    resetPassword(newPassword, resetPasswordEmail);
    setFormSent(true);
  }, [resetPassword, newPassword, resetPasswordEmail]);

  const handleNavigateToLogin = useCallback(() => {
    navigate(baseUrl);
  }, [navigate]);

  return (
    <div className="reset-password">
      <div className="reset-password__form">
        {!formSent ? (
          <form onSubmit={handleSubmitForm}>
            <NgirLogoIcon />
            <Text
              className="reset-password__form__header"
              text={t("pages.createNewPassword.title")}
              color="dark"
              size="medium"
              fontWeight={600}
            />
            <Text
              className="reset-password__form__desc"
              text={t("pages.createNewPassword.desc")}
              color="dark-contrast"
              size="small"
              fontWeight={400}
            />
            <div className="reset-password__form__input">
              <input
                type="password"
                placeholder={
                  t("pages.createNewPassword.newPasswordPlaceholder") as string
                }
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <button className="btn" type="submit" onClick={handleSubmitForm}>
                {t("pages.createNewPassword.submitNewPassword")}
              </button>
            </div>
          </form>
        ) : (
          <form>
            <NgirLogoIcon />
            <Text
              className="reset-password__form__header"
              text={t("pages.createNewPassword.newPasswordSet")}
              color="dark"
              size="medium"
              fontWeight={600}
            />
            <Text
              className="reset-password__form__desc"
              text={t("pages.createNewPassword.newPasswordSetDesc")}
              color="dark-contrast"
              size="small"
              fontWeight={400}
            />
            <button className="btn" onClick={handleNavigateToLogin}>
              {t("pages.createNewPassword.login")}
            </button>
          </form>
        )}
      </div>
      {!formSent && (
        <div
          className="cancel-reset-password"
          onClick={() => navigate(baseUrl)}
        >
          <Text text={t("common.cancel")} size="xxxs" />
          <Icon name="cross" className="cross" />
        </div>
      )}
    </div>
  );
};
