import React, { useEffect, useState } from "react";

import { UserContextProvider } from "./context/user-context/UserContext";
import { Environment, setupEnvironment } from "./environment/environment";
import { Router } from "./router/Router";
import loadProjection from "@avinet/adaptive-ui-maps/utils/loadProjection";
import { projection } from "./constants/Projection";

function App() {
  const [environment, setEnvironment] = useState<Environment | undefined>(
    undefined
  );

  useEffect(() => {
    (async () => {
      try {
        await loadProjection(projection);
      } catch (err) {
        console.error(`Error loading projection ${err}`);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        await setupEnvironment().then((env) => setEnvironment(env));
      } catch (err) {
        console.error(`Error setup ${err}`);
      }
    })();
  }, []);

  if (!environment) {
    return null;
  } else {
    return (
      <UserContextProvider environment={environment}>
        <Router />
      </UserContextProvider>
    );
  }
}

export default App;
