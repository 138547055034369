import React, {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Feature from "ol/Feature";
import PageLayout from "../../components/pagelayout/PageLayout";
import { MapWrapper } from "../../components/map/MapWrapper";
import { MapProvider } from "@avinet/adaptive-ui-maps";
import LOCAL_STORAGE from "../../constants/LocalStorage";
import { Outlet } from "react-router-dom";
import Point from "ol/geom/Point";
import "./Map.scss";
import { transform } from "ol/proj";
import Icon from "@avinet/adaptive-ui-core/ui/Icon";
import { Text } from "../../components/text/Text";
import { DEFAULT_API_CONFIG } from "../../api/api-config";
import { ILayer, IVectorLayerFeature } from "../../utils/data-types";
import { t } from "i18next";
import WKT from "ol/format/WKT";
import { projection } from "../../constants/Projection";
import { MapOptions } from "../../components/map-options/MapOptions";
import FlipButtons from "../../components/buttons/FlipButtons";
import { TextButton } from "../../components/buttons/TextButton";
import { useDrawing } from "../../context/drawing-context/DrawingContext";
import { useMobileView } from "../../context/mobile-view-context/MobileViewContext";
import { DRAWING_OBJECT_TYPES, MAP_OPTIONS } from "../../constants/Constants";

export const Map = () => {
  const {
    isDrawMode,
    setIsDrawMode,
    isMovingPoint,
    setIsMovingPoint,
    hasDrawn,
    setHasDrawn,
    setDrawnObject,
    isSendingWKT,
    setIsSendingWKT,
    drawnObjectType,
    setDrawnObjectType,
  } = useDrawing();
  const { isFullscreenMap, onToggleFullscreenMap } = useMobileView();
  const mapContentRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const [isShowingFeaturesList, setIsShowingFeaturesList] =
    useState<boolean>(false);
  const [selectedBoxId, setSelectedBoxId] = useState<number | null>(null);
  const [selectedDigiThemeUuid, setSelectedDigiThemeUuid] =
    useState<string>("");
  const [selectedFieldName, setSelectedFieldName] = useState<string>("");
  const [selectedLayerName, setSelectedLayerName] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [userCoords, setUserCoords] = useState<number[]>([]);
  const [featuresWKT1, setFeaturesWKT1] = useState<Feature[]>([]);
  const [featuresWKT2, setFeaturesWKT2] = useState<Feature[]>([]);
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);
  const [vectorLayerFeatures, setVectorLayerFeatures] = useState<
    IVectorLayerFeature[]
  >([]);
  const [extent, setExtent] = useState<number[]>([]);
  const [zoomLevel, setZoomLevel] = useState<number>();

  const handleSelectedOptionChange = useCallback((option: string) => {
    setSelectedOption(option);
  }, []);

  const handleSetIsSendingWKT = useCallback(
    (isSending: boolean) => {
      setIsSendingWKT(isSending);
    },
    [setIsSendingWKT]
  );

  const handleDoneDrawing = useCallback(() => {
    if (!isMovingPoint) handleSetIsSendingWKT(true);
    setIsDrawMode(false);
  }, [handleSetIsSendingWKT, isMovingPoint, setIsDrawMode]);

  const handleCancelDrawMode = useCallback(
    (option: string) => {
      handleSelectedOptionChange(option ?? "");
      setIsDrawMode(false);
      handleSetIsSendingWKT(false);
      setDrawnObject(null);
      setHasDrawn(false);
    },
    [
      handleSelectedOptionChange,
      handleSetIsSendingWKT,
      setDrawnObject,
      setHasDrawn,
      setIsDrawMode,
    ]
  );

  useEffect(() => {
    if (isSendingWKT) handleSelectedOptionChange(MAP_OPTIONS.MESSAGE);
  }, [handleSelectedOptionChange, isSendingWKT]);

  const handleSetSelectedBoxId = useCallback(
    (id: number | null) => {
      setSelectedBoxId(id);
      if (id !== null) setIsDrawMode(false);
    },
    [setIsDrawMode]
  );

  const handleIsDrawMode = useCallback(() => {
    if (isDrawMode || isSendingWKT) {
      handleCancelDrawMode("");
      setIsSendingWKT(false);
    } else {
      setIsDrawMode(true);
    }
  }, [
    handleCancelDrawMode,
    isDrawMode,
    isSendingWKT,
    setIsDrawMode,
    setIsSendingWKT,
  ]);

  const handleBoxClick = useCallback(
    (index: number) => {
      handleSetSelectedBoxId(index === selectedBoxId ? null : index);
      handleCancelDrawMode(selectedOption);
    },
    [
      handleCancelDrawMode,
      handleSetSelectedBoxId,
      selectedBoxId,
      selectedOption,
    ]
  );

  const showNewRegistrationButton = useMemo(() => {
    return selectedLayerName.includes("Meldingsobjekt");
  }, [selectedLayerName]);

  const defaultExtent = useMemo(() => [461314, 8462004, 707752, 8715243], []);

  const extentFromStore = useMemo(() => {
    return localStorage.getItem(LOCAL_STORAGE.MAP_EXTENT) !== "[]"
      ? JSON.parse(localStorage.getItem(LOCAL_STORAGE.MAP_EXTENT) ?? "")
      : defaultExtent;
  }, [defaultExtent]);

  const getZoomFromMapWrapper = useCallback((zoom: number) => {
    setZoomLevel(zoom);
  }, []);

  const getExtentFromMapWrapper = useCallback(
    (newExtent: number[]) => {
      const isExtentValid = newExtent.every(
        (value) => typeof value === "number" && !Number.isNaN(value)
      );

      // Check if new extent is different from the current extent
      const extentHasChanged = !extent.every(
        (value, index) => value === newExtent[index]
      );

      if (isExtentValid && extentHasChanged) {
        setExtent(newExtent);
      } else if (!extentHasChanged) {
        setExtent(isExtentValid ? newExtent : extentFromStore);
      }
    },
    [extent, extentFromStore] // Only rerun if the current extent changes
  );

  const vectorLayers = useMemo(() => {
    if (extent.length === 0) return [];
    return localStorage.getItem(LOCAL_STORAGE.MAP_LAYERS_WITH_UUID)
      ? JSON.parse(
          localStorage.getItem(LOCAL_STORAGE.MAP_LAYERS_WITH_UUID) ?? ""
        )
      : [];
  }, [extent]);

  useEffect(() => {
    if (isMovingPoint) {
      setDrawnObjectType(DRAWING_OBJECT_TYPES.POINT);
    }
  }, [isMovingPoint, setDrawnObjectType]);

  const taskRefs = useRef<(HTMLDivElement | null)[]>([]);

  const scrollToTask = useCallback((index: number) => {
    const taskElement = taskRefs.current[index];
    if (taskElement) {
      taskElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, []);

  useEffect(() => {
    if (selectedBoxId !== null) {
      const index = vectorLayerFeatures?.findIndex(
        (object) => object.tbl_id === selectedBoxId
      );
      if (index !== undefined && index !== -1) {
        scrollToTask(index);
      }
    }
  }, [selectedBoxId, scrollToTask, vectorLayerFeatures]);

  useEffect(() => {
    if (!isShowingFeaturesList) {
      setIsSendingWKT(false);
      setIsMovingPoint(false);
      setIsDrawMode(false);
      setDrawnObjectType(null);
    }
  }, [
    isShowingFeaturesList,
    setDrawnObjectType,
    setIsDrawMode,
    setIsMovingPoint,
    setIsSendingWKT,
  ]);

  const nearbyVectorLayerRequestUrl = useMemo(() => {
    return (
      DEFAULT_API_CONFIG.url + "/diaryorder/feature/getLayerObjectsWithinExtent"
    );
  }, []);

  const gm_session_id = useMemo(() => {
    const user_store = localStorage.getItem(LOCAL_STORAGE.USER);
    const userObject = JSON.parse(user_store ?? "{}");
    return userObject.session_id;
  }, []);

  const getUserPositionFromMapWrapper = useCallback((position: Point) => {
    setUserCoords(position.getFlatCoordinates());
  }, []);

  const fetchVectorLayerFeatures = useCallback(
    (digi_theme_uuid: string, field_name: string) => {
      if (zoomLevel && zoomLevel < 12) {
        setVectorLayerFeatures([]);
        return;
      }

      const point1 = transform([extent[0], extent[1]], "EPSG:3857", projection);
      const point2 = transform([extent[2], extent[3]], "EPSG:3857", projection);

      const request = {
        digi_theme_uuid: digi_theme_uuid,
        field_name: field_name,
        lat: point1[1],
        lon: point1[0],
        lat2: point2[1],
        lon2: point2[0],
      };
      fetch(nearbyVectorLayerRequestUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          gm_session_id: gm_session_id,
        },
        body: JSON.stringify(request),
      })
        .then((response) => response.json())
        .then((data) => {
          setVectorLayerFeatures(data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
    [extent, gm_session_id, nearbyVectorLayerRequestUrl, zoomLevel]
  );

  const handleSetIsShowingFeaturesList = useCallback(() => {
    if (isShowingFeaturesList) {
      setFeaturesWKT1([]);
      setFeaturesWKT2([]);
      setSelectedBoxId(null);
      setDrawnObjectType(null);
      setIsDrawMode(false);
    }
    setIsShowingFeaturesList((prev) => !prev);
  }, [isShowingFeaturesList, setDrawnObjectType, setIsDrawMode]);

  const handleGetNearbyFeatures = useCallback(
    (digi_theme_uuid: string, layer_name: string, field_name: string) => {
      fetchVectorLayerFeatures(digi_theme_uuid, field_name);
      setSelectedDigiThemeUuid(digi_theme_uuid);
      setSelectedFieldName(field_name);
      setSelectedLayerName(layer_name);
      if (layer_name === "Meldingsobjekt punkt")
        setDrawnObjectType(DRAWING_OBJECT_TYPES.POINT);
      if (layer_name === "Meldingsobjekt linje")
        setDrawnObjectType(DRAWING_OBJECT_TYPES.LINE);
      handleSetIsShowingFeaturesList();
    },
    [
      fetchVectorLayerFeatures,
      handleSetIsShowingFeaturesList,
      setDrawnObjectType,
    ]
  );

  // fetch VectorLayerFeatures again when extent changes
  useEffect(() => {
    if (selectedDigiThemeUuid) {
      fetchVectorLayerFeatures(selectedDigiThemeUuid, selectedFieldName ?? "");
    }
  }, [
    fetchVectorLayerFeatures,
    selectedDigiThemeUuid,
    selectedFieldName,
    selectedLayerName,
  ]);

  // function that takes in a Point and a WKT string and calculates the distance between them
  const calculateDistance = useCallback((userPos: number[], wkt: string) => {
    const userPoint = new Point(userPos);
    const featureWkt = new WKT().readFeature(wkt);

    const userPointCoords = userPoint.getFlatCoordinates();
    const geometry = featureWkt.getGeometry();

    if (userPointCoords.some((coord) => Number.isNaN(coord)))
      return t("pages.map.noPosition");
    userPoint.transform("EPSG:3857", projection);

    if (geometry instanceof Point) {
      const wktPointCoords = geometry.getFlatCoordinates();
      const x1 = userPointCoords[0];
      const y1 = userPointCoords[1];

      const x2 = wktPointCoords[0];
      const y2 = wktPointCoords[1];

      const distanceSquared = Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2);
      return Math.sqrt(distanceSquared).toFixed(2) + " m";
    }

    console.error("Geometry is not a Point.");
    return ""; // Handle the case when geometry is not a Point
  }, []);

  const createFeaturesWKT1 = useMemo<Feature[]>(() => {
    if (!vectorLayerFeatures || vectorLayerFeatures.length === 0) return [];
    return vectorLayerFeatures.map((feature: IVectorLayerFeature) => {
      const featureWkt = new WKT().readFeature(feature.wkt);
      featureWkt.setId(feature.tbl_id);
      const geometry = featureWkt.getGeometry();
      if (geometry) {
        geometry.transform(projection, "EPSG:3857");
      }
      return featureWkt;
    });
  }, [vectorLayerFeatures]);

  const createFeaturesWKT2 = useMemo<Feature[]>(() => {
    if (!vectorLayerFeatures || vectorLayerFeatures.length === 0) return [];
    return vectorLayerFeatures.map((feature: IVectorLayerFeature) => {
      const featureWkt =
        feature.wkt2 === ""
          ? new WKT().readFeature(feature.wkt)
          : new WKT().readFeature(feature.wkt2);
      featureWkt.setId(feature.tbl_id);
      const geometry = featureWkt.getGeometry();
      if (geometry) {
        geometry.transform(projection, "EPSG:3857");
      }
      return featureWkt;
    });
  }, [vectorLayerFeatures]);

  useEffect(() => {
    if (isShowingFeaturesList) setFeaturesWKT1(createFeaturesWKT1);
  }, [createFeaturesWKT1, isShowingFeaturesList]);

  useEffect(() => {
    if (isShowingFeaturesList) setFeaturesWKT2(createFeaturesWKT2);
  }, [createFeaturesWKT2, isShowingFeaturesList]);

  const messageData = useMemo(() => {
    const vectorLayerThatMatchesId = vectorLayerFeatures.find(
      (feature) => feature.tbl_id === selectedBoxId
    );
    return vectorLayerThatMatchesId;
  }, [selectedBoxId, vectorLayerFeatures]);

  useEffect(() => {
    window.addEventListener("online", () => setIsOnline(true));
    window.addEventListener("offline", () => setIsOnline(false));

    return () => {
      window.removeEventListener("online", () => setIsOnline(true));
      window.removeEventListener("offline", () => setIsOnline(false));
    };
  }, []);

  const cancelCurrentDrawing = useCallback(() => {
    setIsDrawMode(false);
    setIsMovingPoint(false);
    setDrawnObjectType(null);
  }, [setDrawnObjectType, setIsDrawMode, setIsMovingPoint]);

  return (
    <>
      <PageLayout>
        <div className={`map-page ${isFullscreenMap ? "small" : ""}`}>
          <div className="map-page-menu">
            <div
              className={`back-btn ${
                isShowingFeaturesList && !isFullscreenMap ? "space-between" : ""
              }`}
            >
              {isShowingFeaturesList && !isFullscreenMap && (
                <div
                  className="flex-box"
                  onClick={handleSetIsShowingFeaturesList}
                >
                  <Icon name="arrow" flip className="arrow" />
                  <Text text={t("common.back")} size="xxxs" />
                </div>
              )}

              <div className="expand-container" onClick={onToggleFullscreenMap}>
                <Icon
                  name="chevron"
                  className="expand-icon"
                  flip={!isFullscreenMap}
                />
              </div>
            </div>
            {!isFullscreenMap && !isShowingFeaturesList && (
              <div className="map-page-content">
                <Text
                  text={t("pages.map.themes")}
                  size="small"
                  fontWeight={600}
                />
                <div className="map-page-content__vectors">
                  {vectorLayers.length > 0 &&
                    vectorLayers.map((layer: ILayer) => (
                      <div
                        key={layer.digi_theme_uuid}
                        className="vector-layer"
                        onClick={() =>
                          handleGetNearbyFeatures(
                            layer.digi_theme_uuid,
                            layer.name,
                            layer.field_name
                          )
                        }
                      >
                        <Text text={layer.name} size="xxs" />
                        <Icon name="chevron" />
                      </div>
                    ))}
                </div>
              </div>
            )}
            {isShowingFeaturesList && !isFullscreenMap && (
              <div className="map-page-content">
                <Text text={selectedLayerName} size="small" fontWeight={600} />

                <div
                  className={`map-page-content__vectors ${
                    drawnObjectType ? "message-drawmode" : ""
                  }`}
                >
                  {vectorLayerFeatures.map(
                    (feature: IVectorLayerFeature, index: number) => (
                      <div
                        key={feature.tbl_id}
                        ref={(el) => (taskRefs.current[index] = el)}
                        className={`feature ${
                          selectedBoxId === feature.tbl_id ? "selected" : ""
                        }`}
                        onClick={() => handleBoxClick(feature.tbl_id)}
                      >
                        <div className="map-layer-row">
                          <Text
                            className="map-layer-left-text"
                            text={`#${feature.tbl_id.toString()}`}
                            size="xxs"
                          />
                          <Text
                            className="map-layer-right-text"
                            text={feature.layer_name}
                            size="xxs"
                          />
                        </div>
                        <div className="map-layer-row">
                          <Text
                            className="map-layer-left-text"
                            text={""}
                            size="xxs"
                            fontWeight={600}
                          />
                          <Text
                            className="map-layer-right-text"
                            text={feature.desc}
                            size="xxs"
                            fontWeight={600}
                          />
                        </div>
                        <div className="map-layer-row">
                          <Text
                            className="map-layer-left-text"
                            text={t("pages.map.distance")}
                            size="xxxs"
                          />
                          <Text
                            className="map-layer-right-text"
                            text={calculateDistance(userCoords, feature.wkt)}
                            size="xxxs"
                          />
                        </div>
                      </div>
                    )
                  )}
                  {vectorLayerFeatures.length === 0 &&
                    zoomLevel &&
                    zoomLevel < 12 && (
                      <Text
                        text={t("pages.map.zoomIn")}
                        size="xxs"
                        className="empty-vectors-text"
                      />
                    )}
                  {vectorLayerFeatures.length === 0 &&
                    zoomLevel &&
                    zoomLevel >= 12 && (
                      <Text
                        text={t("pages.map.noFeatures")}
                        size="xxs"
                        className="empty-vectors-text"
                      />
                    )}
                </div>
                <div className="map-page-content__buttons">
                  {showNewRegistrationButton && (
                    <TextButton
                      className={`btn message-drawmode ${
                        isDrawMode ? "active" : ""
                      }`}
                      onClick={handleIsDrawMode}
                      icon={
                        <Icon
                          name={isDrawMode || isSendingWKT ? "cross" : "edit"}
                          className="select-point-icon"
                        />
                      }
                      text={t("pages.map.newRegistration")}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
          <MapOptions
            className={`${
              isFullscreenMap
                ? "collapsed-options-menu"
                : "expanded-options-menu"
            } map`}
            dataFromMap={messageData}
            selectedBoxId={selectedBoxId}
            digiThemeUuid={selectedDigiThemeUuid}
            onSelectedOptionChange={handleSelectedOptionChange}
            selectedOptionFromParent={selectedOption}
            fetchVectorLayerFeatures={() =>
              fetchVectorLayerFeatures(selectedDigiThemeUuid, selectedFieldName)
            }
            handleCloseMessagePopup={() => handleCancelDrawMode("")}
          />
          {isDrawMode && !isSendingWKT && (
            <FlipButtons
              onCancel={cancelCurrentDrawing}
              hasDrawn={hasDrawn}
              onDone={handleDoneDrawing}
            />
          )}
        </div>
        {!isOnline && (
          <Text
            text={t("pages.orders.offlineText")}
            size="xxs"
            className="offline-text"
          />
        )}
      </PageLayout>
      {isFullscreenMap && (
        <div className="collapse-container" onClick={onToggleFullscreenMap}>
          <Icon
            name="chevron"
            className="expand-icon"
            flip={!isFullscreenMap}
          />
        </div>
      )}
      <div className={`map-page-map ${isFullscreenMap ? "fullscreen" : ""}`}>
        <MapProvider initialExtent={extent} minZoom={6} maxZoom={18}>
          <MapWrapper
            selectedId={selectedBoxId}
            defaultExtent={extent}
            featuresWKT1={featuresWKT1}
            featuresWKT2={featuresWKT2}
            selectedOption={selectedOption}
            onFeatureMapClick={handleSetSelectedBoxId}
            sendExtentToParent={getExtentFromMapWrapper}
            sendZoomToParent={getZoomFromMapWrapper}
            sendPositionToParent={getUserPositionFromMapWrapper}
          >
            <div ref={mapContentRef} className="map-layout--content">
              <Outlet />
            </div>
          </MapWrapper>
        </MapProvider>
      </div>
    </>
  );
};
