import superagent from "superagent";

import { ApiConfig, DEFAULT_API_CONFIG } from "../../api/api-config";

export class Api {
  agent: superagent.SuperAgentStatic;
  config: ApiConfig;
  authToken?: string | null;

  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config;
    this.agent = superagent;
  }

  setAuthToken(token?: string | null) {
    this.authToken = token;
  }

  async setup() {
    const agent = superagent.agent().timeout(this.config.timeout);

    agent.set("Accept", "application/json");

    agent.use((request: superagent.Request) => {
      if (this.authToken) {
        request.set("gm_session_id", this.authToken);
      }
    });
  }
}
