import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { Text } from "../../components/text/Text";
import OtpInput from "react-otp-input";

import "./VerifyCodeInput.scss";
import { PlainTextButton } from "../buttons/PlainTextButton";
import { Icon } from "../icon/Icon";
import { useNavigate } from "react-router-dom";
import { baseUrl, resetPasswordUrl } from "../../constants/Routes";

interface VerifyCodeInputProps {
  codeLength?: number;
  verifyCodeValue: string;
  setVerifyCodeValue: Dispatch<SetStateAction<string>>;
  resentCode: () => void;
  onClickVerify: () => void;
  error: boolean;
}

export const VerifyCodeInput = ({
  verifyCodeValue,
  setVerifyCodeValue,
  codeLength,
  resentCode,
  onClickVerify,
  error,
}: VerifyCodeInputProps) => {
  const { t } = useTranslation();
  const [counter, setCounter] = useState(0);
  const inputLength = useMemo(
    () => (codeLength ? codeLength : 4),
    [codeLength]
  );

  const navigate = useNavigate();

  const handleResetCode = useCallback(() => {
    resentCode();
    setCounter(30);
  }, [resentCode]);

  const handleClearForm = useCallback(() => {
    setVerifyCodeValue("");
  }, [setVerifyCodeValue]);

  useEffect(() => {
    if (counter > 0) {
      const interval = setInterval(() => {
        setCounter((counter) => counter - 1);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [counter]);

  return (
    <div className="verify-code">
      <OtpInput
        value={verifyCodeValue}
        onChange={setVerifyCodeValue}
        numInputs={inputLength}
        inputType="number"
        shouldAutoFocus={verifyCodeValue.length !== inputLength}
        renderInput={(props) => <input {...props} />}
        containerStyle={`verify-code__otp ${
          verifyCodeValue.length === inputLength
            ? "verify-code__otp--filled"
            : ""
        } ${error ? "verify-code__otp--error" : ""}`}
        inputStyle="verify-code__input"
      />
      <div className="verify-code__resend">
        <p>{t("pages.enterCode.getCodeText")}</p>
        <PlainTextButton
          disabled={counter > 0}
          onClick={handleResetCode}
          text={`${t("pages.enterCode.getCodeLink")}${
            counter > 0 ? ` (${counter}).` : "."
          } `}
        />
      </div>
      <div className="verify-code__buttons">
        {error && (
          <div className="verify-code__wrong-code">
            <p>{t("errors.wrongCode")}</p>
          </div>
        )}
        <button
          type="button"
          className="btn btn-clear verify-code__buttons--clear"
          onClick={handleClearForm}
        >
          {t("common.clear")}
        </button>
        <button
          className="btn"
          onClick={onClickVerify}
          disabled={verifyCodeValue.length !== inputLength}
        >
          {t("common.verify")}
        </button>
      </div>
      <div className="previous-page" onClick={() => navigate(resetPasswordUrl)}>
        <Icon name="arrow" flip className="arrow" />
        <Text text={t("common.back")} size="xxxs" />
      </div>

      <div className="cancel-reset-password" onClick={() => navigate(baseUrl)}>
        <Text text={t("common.cancel")} size="xxxs" />
        <Icon name="cross" className="cross" />
      </div>
    </div>
  );
};
