import React from "react";

import { Text } from "../text/Text";

import "./TextButton.scss";

interface TextButtonProps {
  text: string;
  onClick: () => void;
  disabled?: boolean;
  className?: string;
  invertColors?: boolean;
  icon?: JSX.Element;
  iconPosition?: "left" | "right";
  bold?: boolean;
}

export const TextButton = ({
  text,
  onClick,
  disabled,
  className,
  invertColors,
  icon,
  iconPosition = "left",
  bold,
}: TextButtonProps) => {
  return (
    <button
      className={`btn text-button ${invertColors ? "inverted" : ""} ${
        className ?? ""
      }`}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && iconPosition === "left" && icon}
      <Text text={text} size="xxs" fontWeight={bold ? 600 : 400} />
      {icon && iconPosition === "right" && icon}
    </button>
  );
};
