import { t } from "i18next";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router";

import { NgirLogoIcon } from "../../assets/icons/NgirLogoIcon";
import { Text } from "../../components/text/Text";
import { baseUrl, enterCodeUrl } from "../../constants/Routes";
import { UserContext } from "../../context/user-context/UserContext";
import "./ResetPassword.scss";
import { Icon } from "../../components/icon/Icon";

export const ResetPassword = () => {
  const { verifyBegin } = useContext(UserContext);
  const [email, setUsername] = useState("");
  const navigate = useNavigate();

  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    await verifyBegin(email);
    navigate(enterCodeUrl);
  };

  return (
    <div className="reset-password">
      <div className="reset-password__form">
        <form onSubmit={handleResetPassword}>
          <NgirLogoIcon />
          <Text
            className="reset-password__form__header"
            text={t("pages.resetPassword.title")}
            color="dark"
            size="medium"
            fontWeight={600}
          />
          <Text
            className="reset-password__form__desc"
            text={t("pages.resetPassword.desc")}
            color="dark-contrast"
            size="small"
            fontWeight={400}
          />
          <div className="reset-password__form__input email">
            <input
              type="text"
              placeholder={t("pages.login.emailPlaceholder") as string}
              value={email}
              onChange={(e) => setUsername(e.target.value)}
            />
            <button className="btn" type="submit" onClick={handleResetPassword}>
              {t("pages.resetPassword.submitCode")}
            </button>
          </div>
        </form>
      </div>

      <div className="previous-page" onClick={() => navigate(baseUrl)}>
        <Icon name="arrow" flip className="arrow" />
        <Text text={t("common.back")} size="xxxs" />
      </div>

      <div className="cancel-reset-password" onClick={() => navigate(baseUrl)}>
        <Text text={t("common.cancel")} size="xxxs" />
        <Icon name="cross" className="cross" />
      </div>
    </div>
  );
};
