import "./LoadingMask.scss";

export function LoadingMask({
  label,
  small,
}: {
  label?: string;
  small?: boolean;
}) {
  return (
    <div className="loading-mask">
      <h3>
        <span className="label">{label}</span>{" "}
        <span className={`loading-spinner ${small ? "small" : ""}`}>
          <span />
          <span />
          <span />
        </span>
      </h3>
    </div>
  );
}
