import { isAfter } from "date-fns";
import React, {
  Context,
  FC,
  createContext,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import superagent, { ResponseError } from "superagent";

import { DEFAULT_API_CONFIG } from "../../api/api-config";
import LOCAL_STORAGE from "../../constants/LocalStorage";
import { UserApi } from "../../services/UserApi";
import { UserData, UserItem } from "../../services/api/types";
import { getFromStore, saveToStore } from "../../utils/local-storage";
import { ContextStateValue, IContext } from "../ContextInterfaces";
import { ordersOverviewUrl } from "../../constants/Routes";

interface UserContextConfig {
  user: ContextStateValue<UserItem>;
  isLogin: boolean;
  loginUser: (
    email: string,
    password: string,
    setShowError: React.Dispatch<React.SetStateAction<boolean>>
  ) => Promise<void>;
  verifyBegin: (email: string) => Promise<void>;
  verify: (code: string, email: string | undefined) => Promise<void>;
  logoutUser: () => void;
  resetPassword: (
    newPassword: string,
    email: string | undefined
  ) => Promise<void>;
  token: string | null;
  userProfile?: UserData;
  userProfileLoading: boolean | undefined;
  resetPasswordEmail?: string;
}

const defaultConfig: UserContextConfig = {
  user: { loading: false, value: undefined },
  isLogin: false,
  loginUser: async () => Promise.reject(),
  verifyBegin: async () => Promise.reject(),
  verify: async () => Promise.reject(),
  logoutUser: () => Promise.reject(),
  resetPassword: async () => Promise.reject(),
  token: null,
  userProfile: undefined,
  userProfileLoading: undefined,
  resetPasswordEmail: undefined,
};

export const UserContext: Context<UserContextConfig> =
  createContext<UserContextConfig>(defaultConfig);

export const UserContextProvider: FC<IContext> = ({
  children,
  environment,
}) => {
  const [user, setUser] = useState<ContextStateValue<UserItem>>({
    loading: undefined,
    value: undefined,
    error: undefined,
  });
  const [token, setToken] = useState<string | null>(null);
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [userProfile, setUserProfile] = useState<UserData>(undefined);
  const [userProfileLoading, setUserProfileLoading] = useState<boolean>();

  const loginUser = useCallback(
    async (
      email: string,
      password: string,
      setShowError: React.Dispatch<React.SetStateAction<boolean>>
    ) => {
      try {
        const response = await superagent
          .post(DEFAULT_API_CONFIG.url + "/Auth/Authenticate")
          .send({ email, password });

        if (response.status === 200) {
          setToken(response.body.session_id || null);
          setUser((prevState) => ({
            ...prevState,
            loading: false,
            value: response.body,
            error: null,
          }));
          setIsLogin(true);
          await saveToStore<UserItem>(LOCAL_STORAGE.USER, response.body);
          window.location.href = ordersOverviewUrl;
        } else {
          console.error("Login failed:", response.body.message);
        }
      } catch (error) {
        if ((error as ResponseError).status === 401) {
          setShowError(true);
        }
        console.error("Login failed:", error);
      }
    },
    []
  );

  const verifyBegin = useCallback(async (email: string) => {
    try {
      await superagent
        .post(DEFAULT_API_CONFIG.url + "/Auth/VerifyBegin")
        .send({ email });
      await saveToStore<string>(LOCAL_STORAGE.RESET_PASSWORD_EMAIL, email);
    } catch (error) {
      console.error("VerifyBegin failed:", error);
    }
  }, []);

  const verify = useCallback(
    async (code: string, email: string | undefined) => {
      try {
        const response = await superagent
          .post(DEFAULT_API_CONFIG.url + "/Auth/Verify")
          .send({ code, email });
        await saveToStore<string>(
          LOCAL_STORAGE.RESET_PASSWORD_TOKEN,
          response.body.token
        );
        return response.body.token;
      } catch (error) {
        console.error("Verify failed:", error);
      }
    },
    []
  );

  const resetPassword = useCallback(
    async (newPassword: string, email: string | undefined) => {
      try {
        const resetPasswordToken = await getFromStore<string>(
          LOCAL_STORAGE.RESET_PASSWORD_TOKEN
        );
        await superagent
          .post(DEFAULT_API_CONFIG.url + "/Auth/ResetPassword")
          .send({ token: resetPasswordToken, newPassword, email });
      } catch (error) {
        console.error("ChangePassword failed:", error);
      }
    },
    []
  );

  const logoutUser = useCallback(async () => {
    setIsLogin(false);
    setToken(null);
    setUserProfile(undefined);
    setUserProfileLoading(false);

    await saveToStore<UserItem>(LOCAL_STORAGE.USER, {
      uuid: user?.value?.uuid,
      session_id: undefined,
      expiration_time: undefined,
    });
    setUser({ loading: false, value: undefined });
  }, [user?.value?.uuid]);

  const getUser = useCallback(async () => {
    setUserProfileLoading(true);
    const response = await new UserApi(environment.api).getUserData(
      token ?? ""
    );
    setUserProfileLoading(false);

    if (response) {
      setUserProfile(response.body);
      return true;
    } else {
      setUserProfile(undefined);
      return false;
    }
  }, [environment.api, token]);

  useEffect(() => {
    (async function () {
      const userStore = await getFromStore<UserItem>(LOCAL_STORAGE.USER);
      if (userStore) {
        if (isAfter(new Date(), userStore.expiration_time as number)) {
          setToken(userStore.session_id || null);
          setUser({ value: userStore, loading: false, error: null });
        } else {
          await logoutUser();
        }
      }
    })();
  }, [environment, logoutUser]);

  useEffect(() => {
    token && environment.addAuthToken(token);
    token && !userProfile?.userData && getUser();
  }, [environment, token, userProfile?.userData, getUser]);

  useLayoutEffect(() => {
    if (user.loading !== false) return;
    setIsLogin(!!token && user.value !== undefined);
  }, [token, user]);

  if (!environment) {
    return null;
  }

  return (
    <UserContext.Provider
      value={{
        user,
        isLogin,
        userProfile,
        userProfileLoading,
        loginUser,
        verifyBegin,
        verify,
        logoutUser,
        resetPassword,
        token,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
