import React, { useCallback } from "react";
import { Text } from "../text/Text";

import "./ConfirmationModalContent.scss";
import { t } from "i18next";
import { OrderObject } from "../../utils/data-types";
import { Icon } from "../icon/Icon";

export const ConfirmationModalContent = ({
  onConfirm,
  onclose,
  orderObject,
}: {
  onConfirm: () => void;
  onclose: () => void;
  orderObject: OrderObject | undefined;
}) => {
  const orderTitle = useCallback((text: OrderObject | undefined) => {
    if (!text) {
      return "";
    }
    const splitBySpace = text.info1.split(" ");
    return splitBySpace.slice(1).join(" ");
  }, []);

  const modalText = t("pages.orderInfo.confirmationModal.text", {
    orderObject: orderTitle(orderObject),
  });

  return (
    <div className="confirmation-modal-content">
      <div className="confirmation-modal-content__text">
        <Text text={modalText} size="xs" />
      </div>
      <div className="confirmation-modal-content__buttons">
        <button className="btn" onClick={onConfirm}>
          <Icon name="checkmarkFluent" />
          {t("common.yes")}
        </button>
        <button className="btn-light" onClick={onclose}>
          <Icon name="cross" />
          {t("common.no")}
        </button>
      </div>
    </div>
  );
};
