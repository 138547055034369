export const currentApi = () => {
  const url = window.location.href;

  if (url.includes("ngir.ordreportal.no")) {
    // NGIR Production
    return { api: "https://www.ngirkart.no/api", customer: "NGIR" };
  } else if (url.includes("ngir-ordreportal.avinet.no")) {
    // NGIR Development
    return { api: "https://ngirdo.avinet.no/api", customer: "NGIR" };
  } else if (url.includes("ngir-order.vercel.app")) {
    // NGIR Vercel
    return { api: "https://ngirdo.avinet.no/api", customer: "NGIR" };
  } else {
    // Default
    return { api: "https://ngirdo.avinet.no/api", customer: "NGIR" }; // Change to the project you are working on (currently NGIR Development)
  }
};
