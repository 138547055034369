import { SVGProps } from "react";

export const SelectedCheckmarkIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="24" height="24" rx="12" fill="#75E0A7" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0964 7.39016L9.93644 14.3002L8.03644 12.2702C7.68644 11.9402 7.13644 11.9202 6.73644 12.2002C6.34644 12.4902 6.23644 13.0002 6.47644 13.4102L8.72644 17.0702C8.94644 17.4102 9.32644 17.6202 9.75644 17.6202C10.1664 17.6202 10.5564 17.4102 10.7764 17.0702C11.1364 16.6002 18.0064 8.41016 18.0064 8.41016C18.9064 7.49016 17.8164 6.68016 17.0964 7.38016V7.39016Z"
        fill="#079455"
      />
    </svg>
  );
};
