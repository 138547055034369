import { useCallback, useMemo } from "react";
import superagent from "superagent";
import { IMessageFiltersRequest } from "../utils/data-types";
import LOCAL_STORAGE from "../constants/LocalStorage";

const useFetchMessageFilters = (
  messageUrl: string,
  messageFiltersRequest: IMessageFiltersRequest
) => {
  const gm_session_id = useMemo(() => {
    const user_store = localStorage.getItem(LOCAL_STORAGE.USER);
    const userObject = JSON.parse(user_store ?? "{}");
    return userObject.session_id;
  }, []);

  const messageFilters = useCallback(async () => {
    try {
      const fetchedDetailCodes = await superagent
        .post(messageUrl)
        .send(messageFiltersRequest)
        .set("Accept", "application/json")
        .set("gm_session_id", gm_session_id);
      return fetchedDetailCodes.body;
    } catch (error) {
      console.error("Error fetching detail codes:", error);
      throw error;
    }
  }, [messageUrl, messageFiltersRequest, gm_session_id]);

  return messageFilters;
};

export default useFetchMessageFilters;
