import { SVGProps } from "react";

export const MapDestinationIcon = (props?: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="36"
      viewBox="0 0 32 36"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25.6569 21.8756C26.5142 20.2681 27 18.433 27 16.4846C27 10.1418 21.8513 5 15.5 5C9.14873 5 4 10.1418 4 16.4846C4 18.5765 4.56002 20.5377 5.53849 22.2269C5.55424 22.2541 5.57008 22.2812 5.58604 22.3082C5.91521 22.8659 6.29022 23.3934 6.70588 23.8855C6.71056 23.891 6.71536 23.8967 6.72005 23.9022C6.7238 23.9066 6.72788 23.9114 6.73163 23.9158H6.70588L13.5304 31.1507C14.5986 32.2831 16.4013 32.2831 17.4696 31.1507L24.2941 23.9158H24.2684C24.272 23.9116 24.2756 23.9073 24.2792 23.9031L24.2803 23.9018C24.3295 23.8438 24.3781 23.7853 24.4261 23.7263C24.8092 23.256 25.1558 22.7547 25.4615 22.2269C25.5286 22.1111 25.5938 21.9939 25.6569 21.8756ZM15.5033 20.8758C13.2617 20.8758 11.4445 19.061 11.4445 16.8224C11.4445 14.5838 13.2617 12.769 15.5033 12.769C17.7449 12.769 19.5621 14.5838 19.5621 16.8224C19.5621 19.061 17.7449 20.8758 15.5033 20.8758Z"
        stroke="white"
        strokeWidth="1.5"
      />
    </svg>
  );
};
