import { createContext, ReactNode, useContext, useState } from "react";

interface DrivingContextType {
  isDriving: boolean;
  setIsDriving: (isDriving: boolean) => void;
}

const DrivingContext = createContext<DrivingContextType | undefined>(undefined);

interface DrivingProviderProps {
  children: ReactNode;
}

// Create the provider component
export const DrivingProvider = ({ children }: DrivingProviderProps) => {
  const [isDriving, setIsDriving] = useState<boolean>(false);

  return (
    <DrivingContext.Provider value={{ isDriving, setIsDriving }}>
      {children}
    </DrivingContext.Provider>
  );
};

// Custom hook to use the DrivingContext
export const useDriving = () => {
  const context = useContext(DrivingContext);

  // Ensure the context is not undefined before using it
  if (context === undefined) {
    throw new Error("useDriving must be used within a DrivingProvider");
  }

  return context;
};
