import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Dropdown } from "../dropdown/Dropdown";
import { t } from "i18next";
import LOCAL_STORAGE from "../../constants/LocalStorage";
import "./DrivingModalContent.scss";
import superagent from "superagent";
import { DEFAULT_API_CONFIG } from "../../api/api-config";
import { useDriving } from "../../context/driving-context/DrivingContext";
import { Text } from "../text/Text";

interface DrivingModalContentProps {
  onClose: () => void;
}

export const DrivingModalContent = ({ onClose }: DrivingModalContentProps) => {
  const { isDriving } = useDriving();
  const [showRequestFailed, setShowRequestFailed] = useState(false);

  const generateUuid = useMemo(() => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }, []);

  const [selectedKilos, setSelectedKilos] = useState<{
    [key: string]: number;
  }>({
    rest_ppp: 0,
    bio: 0,
  });

  const handleKilosOptionChange = useCallback((key: string, value: number) => {
    setSelectedKilos((prevKilos: { [key: string]: number }) => ({
      ...prevKilos,
      [key]: value,
    }));
  }, []);

  useEffect(() => {
    if (isNaN(selectedKilos.rest_ppp)) {
      setSelectedKilos((prevKilos) => ({
        ...prevKilos,
        rest_ppp: 0,
      }));
    }
    if (isNaN(selectedKilos.bio)) {
      setSelectedKilos((prevKilos) => ({
        ...prevKilos,
        bio: 0,
      }));
    }
  }, [selectedKilos]);

  const startWeight = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE.STARTWEIGHT) || "{}"
  );

  const sumRestPPPAndBio = useMemo(() => {
    const totalWeightArray = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE.TOTAL_WEIGHT) || "[]"
    );

    let totalRestPPP = 0;
    let totalBio = 0;

    for (let i = 0; i < totalWeightArray.length; i++) {
      totalRestPPP += parseFloat(totalWeightArray[i].restPPP) || 0;
      totalBio += parseFloat(totalWeightArray[i].bio) || 0;
    }

    return {
      totalRestPPP: totalRestPPP,
      totalBio: totalBio,
      startRestPPP: startWeight.rest_ppp,
      startBio: startWeight.bio,
    };
  }, [startWeight.bio, startWeight.rest_ppp]);

  const sumRestPPP =
    sumRestPPPAndBio.totalRestPPP + sumRestPPPAndBio.startRestPPP;
  const sumBio = sumRestPPPAndBio.totalBio + sumRestPPPAndBio.startBio;

  const shouldDisableSaveButton = useMemo<boolean>(() => {
    if (!isDriving) return false;
    if (isNaN(selectedKilos.rest_ppp) || isNaN(selectedKilos.bio)) return true;
    return selectedKilos.rest_ppp < sumRestPPP || selectedKilos.bio < sumBio;
  }, [isDriving, selectedKilos, sumBio, sumRestPPP]);

  const startWeightRequest = useMemo(() => {
    return {
      job_uuid: generateUuid,
      start_default: selectedKilos.rest_ppp,
      start_extra: selectedKilos.bio,
      end_default: 0,
      end_extra: 0,
    };
  }, [generateUuid, selectedKilos.bio, selectedKilos.rest_ppp]);

  const endWeightRequest = useMemo(() => {
    return {
      job_uuid: localStorage.getItem(LOCAL_STORAGE.JOB_UUID),
      start_default: startWeight.rest_ppp,
      start_extra: startWeight.bio,
      end_default: selectedKilos.rest_ppp,
      end_extra: selectedKilos.bio,
    };
  }, [
    selectedKilos.bio,
    selectedKilos.rest_ppp,
    startWeight.bio,
    startWeight.rest_ppp,
  ]);

  const onSave = useCallback(() => {
    if (!navigator.onLine) {
      setShowRequestFailed(true);
      console.error("No internet connection");
      return;
    }
    const user_store = localStorage.getItem(LOCAL_STORAGE.USER);
    const userObject = JSON.parse(user_store ?? "{}");
    const gm_session_id = userObject.session_id;
    async function putWeight() {
      try {
        await superagent
          .put(DEFAULT_API_CONFIG.url + "/diaryorder/job")
          .send(isDriving ? endWeightRequest : startWeightRequest)
          .set("Accept", "application/json")
          .set("gm_session_id", gm_session_id);
      } catch (error) {
        console.error("Error response", error);
      }
    }
    if (isDriving) {
      putWeight();
      localStorage.removeItem(LOCAL_STORAGE.JOB_UUID);
      localStorage.removeItem(LOCAL_STORAGE.TOTAL_WEIGHT);
      localStorage.removeItem(LOCAL_STORAGE.STARTWEIGHT);
    } else {
      localStorage.setItem(
        LOCAL_STORAGE.STARTWEIGHT,
        JSON.stringify(selectedKilos)
      );
      localStorage.setItem(LOCAL_STORAGE.TOTAL_WEIGHT, "[]");
      localStorage.setItem(LOCAL_STORAGE.JOB_UUID, generateUuid);
      putWeight();
    }
    onClose();
  }, [
    endWeightRequest,
    generateUuid,
    isDriving,
    onClose,
    selectedKilos,
    startWeightRequest,
  ]);

  useEffect(() => {
    // If re-connected to the internet, hide the request failed message
    const interval = setInterval(() => {
      if (navigator.onLine) {
        setShowRequestFailed(false);
      }
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="driving-modal">
      <div className="driving-modal-body">
        <Dropdown
          title="Rest/PPP"
          type="number"
          onSelectChange={(value) =>
            handleKilosOptionChange("rest_ppp", parseInt(value ?? "0"))
          }
          placeholder="0"
          selectedValue={selectedKilos.rest_ppp || 0}
        />
        <Dropdown
          title="Bio"
          type="number"
          onSelectChange={(value) =>
            handleKilosOptionChange("bio", parseInt(value ?? "0"))
          }
          placeholder="0"
          selectedValue={selectedKilos.bio || 0}
        />
      </div>
      {shouldDisableSaveButton && (
        <Text
          className="weight-warning"
          text={t("pages.orderInfo.drivingModal.weightWarning")}
          size="xxs"
        />
      )}
      {isDriving && (
        <div className="driving-modal-sum">
          <div className="driving-modal-sum-title">
            <Text
              text={t("pages.orderInfo.drivingModal.totalWeight")}
              size="xxs"
              fontWeight={600}
            />
          </div>
          <div className="driving-modal-sum-item">
            <Text text={"Rest/PPP:" + " " + sumRestPPP + "kg"} size="xxs" />
            <Text text={"Bio:" + " " + sumBio + "kg"} size="xxs" />
          </div>
        </div>
      )}
      {showRequestFailed && (
        <Text
          className="driving-modal-request-failed"
          text={t("pages.orderInfo.drivingModal.requestFailed")}
          size="xxs"
        />
      )}
      <div className="driving-modal-footer">
        <button className="btn-light" onClick={onClose}>
          {t("common.cancel")}
        </button>
        <button
          className="btn"
          onClick={onSave}
          disabled={shouldDisableSaveButton}
        >
          {t("common.save")}
        </button>
      </div>
    </div>
  );
};
