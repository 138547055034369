import React, { ChangeEvent, useCallback, useRef, useState } from "react";
import "./Search.scss";
import { IWmsLayer } from "../../utils/data-types";
import { Icon } from "../icon/Icon";

interface SearchProps {
  placeholder: string;
  data: IWmsLayer[] | undefined;
  onSearch: (results: IWmsLayer[]) => void;
}

const Search = ({ placeholder, data, onSearch }: SearchProps) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [inputPlaceholder, setInputPlaceholder] = useState<string>(placeholder);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setSearchTerm(value);

      const results = data?.filter((item: IWmsLayer) =>
        item.name.toLowerCase().includes(value.toLowerCase())
      );

      onSearch(results ?? []);
    },
    [data, onSearch]
  );

  const handleInputFocus = useCallback(() => {
    setInputPlaceholder("");
  }, []);

  const handleInputBlur = useCallback(() => {
    if (!searchTerm) {
      setInputPlaceholder(placeholder);
    }
  }, [placeholder, searchTerm]);

  return (
    <div className="search-container">
      <input
        ref={inputRef}
        type="text"
        placeholder={inputPlaceholder}
        value={searchTerm}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
      />
      <Icon name="search" className="search-icon" />
    </div>
  );
};

export default Search;
