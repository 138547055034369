import React, { useEffect, useMemo, useRef, useState } from "react";
import { Html5QrcodeScanner } from "html5-qrcode";
import "./BarcodeScanner.scss";
import { t } from "i18next";

const BarcodeScanner = ({
  isScanning,
  onClose,
  onScan,
}: {
  isScanning: boolean;
  onClose: () => void;
  onScan: (result: string) => void;
}) => {
  const [result, setResult] = useState<string | undefined>(undefined);
  const readerRef = useRef<HTMLDivElement>(null);
  const scannerRef = useRef<Html5QrcodeScanner | null>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setResult(undefined);
    if (isScanning && readerRef.current) {
      const config = { fps: 10, qrbox: 300 };
      const qrCodeSuccessCallback = (decodedText: string) => {
        setResult(decodedText);
        onScan(decodedText);
      };

      const qrCodeErrorCallback = (errorMessage: string) => {
        console.error(errorMessage);
      };

      scannerRef.current = new Html5QrcodeScanner(
        readerRef.current.id,
        config,
        false
      );
      scannerRef.current.render(qrCodeSuccessCallback, qrCodeErrorCallback);
    }

    return () => {
      if (scannerRef.current) {
        scannerRef.current.clear();
        scannerRef.current = null;
      }
    };
  }, [isScanning, onScan]);

  const handleClickOutside = (event: React.MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      onClose();
    }
  };

  const resultString = useMemo(() => {
    return t("pages.scanner.noResults", {
      code: result,
    });
  }, [result]);

  if (!isScanning) return null;

  return (
    <div className="barcode-scanner-modal" onClick={handleClickOutside}>
      <div className="barcode-scanner-content" ref={modalRef}>
        <div
          id="reader"
          ref={readerRef}
          style={{ width: "100%", height: "100%" }}
        />
        {result && <p className="result-text">{resultString}</p>}
      </div>
    </div>
  );
};

export default BarcodeScanner;
