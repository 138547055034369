import { currentApi } from "../utils/customer-config";

const { api } = currentApi();

const API_URL = api;

export interface ApiConfig {
  url: string;
  timeout: number;
}

export const DEFAULT_API_CONFIG: ApiConfig = {
  url: API_URL,
  timeout: 8000,
};
