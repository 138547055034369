import { SVGProps } from "react";

export const SelectedNotCompletedIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="12" cy="12" r="12" fill="#FEC84B" />
      <path
        d="M9.96073 8.45718C9.72132 7.18031 10.7009 6 12 6C13.2991 6 14.2787 7.18031 14.0393 8.45718L13.1843 13.0171C13.0774 13.587 12.5798 14 12 14C11.4202 14 10.9226 13.587 10.8157 13.0171L9.96073 8.45718ZM13.5 16.5C13.5 17.3284 12.8284 18 12 18C11.1716 18 10.5 17.3284 10.5 16.5C10.5 15.6716 11.1716 15 12 15C12.8284 15 13.5 15.6716 13.5 16.5Z"
        fill="#DC6803"
      />
    </svg>
  );
};
