import React, { useCallback, useEffect, useMemo, useState } from "react";

import "./MapOptions.scss";
import {
  IOrder,
  IVectorLayerFeature,
  OrderObject,
} from "../../utils/data-types";
import Icon from "@avinet/adaptive-ui-core/ui/Icon";
import { Text } from "../text/Text";
import { t } from "i18next";
import RegisterObject from "../register-object/RegisterObject";
import ObjectProperties from "../object-properties/ObjectProperties";
import Message from "../message/Message";
import { DEFAULT_API_CONFIG } from "../../api/api-config";
import LOCAL_STORAGE from "../../constants/LocalStorage";
import Log from "../log/Log";
import { MAP_OPTIONS, STATUSES } from "../../constants/Constants";
import { useDrawing } from "../../context/drawing-context/DrawingContext";

export const MapOptions = ({
  className,
  dataFromMap,
  dataFromOrderinfo,
  showRegisterButton,
  selectedBoxId,
  selectedFilter,
  digiThemeUuid,
  onSelectedOptionChange,
  selectedOptionFromParent,
  fetchVectorLayerFeatures,
  handleCloseMessagePopup,
  sendSelectedCodeToParent,
  isMobileView,
  clearSelectedBoxId,
}: {
  className?: string;
  showRegisterButton?: boolean;
  dataFromMap?: IVectorLayerFeature;
  dataFromOrderinfo?: IOrder | undefined;
  selectedBoxId: number | null;
  digiThemeUuid?: string;
  selectedFilter?: string | undefined;
  onSelectedOptionChange: (option: string) => void;
  selectedOptionFromParent?: string;
  fetchVectorLayerFeatures?: () => void;
  handleCloseMessagePopup: () => void;
  sendSelectedCodeToParent?: (code: string) => void;
  isMobileView?: boolean;
  clearSelectedBoxId?: () => void;
}) => {
  const { isDrawMode, setDrawnObject, isSendingWKT } = useDrawing();
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);
  const [selectedOption, setSelectedOption] = useState<string>(
    selectedOptionFromParent || ""
  );
  const [isHidden, setIsHidden] = useState<boolean>(false);
  const [mapBoxData, setMapBoxData] = useState<OrderObject | null>();
  const selectedBoxData = useMemo(() => {
    if (dataFromOrderinfo) {
      return dataFromOrderinfo.orderobject.find(
        (orderObject) => orderObject.id === selectedBoxId
      );
    }
  }, [dataFromOrderinfo, selectedBoxId]);

  const handleSelectedOptionChange = useCallback((option: string) => {
    setSelectedOption(option);
  }, []);

  const toggleHideMapOptions = useCallback(() => {
    setIsHidden((prev) => !prev);
  }, []);

  useEffect(() => {
    if (!isDrawMode) {
      setIsHidden(false);
    } else {
      setIsHidden(true);
    }
  }, [isDrawMode]);

  useEffect(() => {
    if (selectedOptionFromParent) {
      handleSelectedOptionChange(selectedOptionFromParent);
    }
  }, [handleSelectedOptionChange, selectedOptionFromParent]);

  const options =
    showRegisterButton && !isMobileView
      ? [
          {
            label: MAP_OPTIONS.REGISTER,
            icon: "status",
            value: MAP_OPTIONS.REGISTER,
          },
          {
            label: MAP_OPTIONS.MESSAGE,
            icon: "chat",
            value: MAP_OPTIONS.MESSAGE,
          },
          {
            label: MAP_OPTIONS.PROPERTIES,
            icon: "list",
            value: MAP_OPTIONS.PROPERTIES,
          },
          { label: MAP_OPTIONS.LOG, icon: "history", value: MAP_OPTIONS.LOG },
          {
            label: MAP_OPTIONS.ZOOM_TO,
            icon: "zoomIn",
            value: MAP_OPTIONS.ZOOM_TO,
          },
        ]
      : isMobileView
      ? [
          {
            label: MAP_OPTIONS.REGISTER,
            icon: "status",
            value: MAP_OPTIONS.REGISTER,
          },
          {
            label: MAP_OPTIONS.MESSAGE,
            icon: "chat",
            value: MAP_OPTIONS.MESSAGE,
          },
          {
            label: MAP_OPTIONS.PROPERTIES,
            icon: "list",
            value: MAP_OPTIONS.PROPERTIES,
          },
          { label: MAP_OPTIONS.LOG, icon: "history", value: MAP_OPTIONS.LOG },
        ]
      : [
          {
            label: MAP_OPTIONS.MESSAGE,
            icon: "chat",
            value: MAP_OPTIONS.MESSAGE,
          },
          {
            label: MAP_OPTIONS.PROPERTIES,
            icon: "list",
            value: MAP_OPTIONS.PROPERTIES,
          },
          { label: MAP_OPTIONS.LOG, icon: "history", value: MAP_OPTIONS.LOG },
          {
            label: MAP_OPTIONS.ZOOM_TO,
            icon: "zoomIn",
            value: MAP_OPTIONS.ZOOM_TO,
          },
        ];

  const handleCloseOptionPopup = useCallback(() => {
    if (isMobileView) {
      handleCloseMessagePopup();
      clearSelectedBoxId && clearSelectedBoxId();
    } else {
      handleSelectedOptionChange("");
      handleCloseMessagePopup();
      setDrawnObject(null);
    }
  }, [
    clearSelectedBoxId,
    handleCloseMessagePopup,
    handleSelectedOptionChange,
    isMobileView,
    setDrawnObject,
  ]);

  const handleSelectOption = useCallback(
    (option: string, forceUpdate = false) => {
      const isSameOption = selectedOption === option;
      const isRestrictedOption =
        !isOnline &&
        option !== MAP_OPTIONS.REGISTER &&
        option !== MAP_OPTIONS.ZOOM_TO;

      if (!forceUpdate && isSameOption) {
        handleCloseOptionPopup();
      } else if (!isRestrictedOption) {
        setSelectedOption(option);
        onSelectedOptionChange(option);
      }
    },
    [handleCloseOptionPopup, isOnline, onSelectedOptionChange, selectedOption]
  );

  const getVectorLayerFeatureUrl = useMemo<string>(() => {
    return DEFAULT_API_CONFIG.url + "/diaryorder/feature/getLayerFeature";
  }, []);

  const user_store = localStorage.getItem(LOCAL_STORAGE.USER);
  const userObject = JSON.parse(user_store ?? "{}");
  const gm_session_id = userObject.session_id;

  const getVectorLayerFeatureRequest = useMemo(() => {
    if (!dataFromOrderinfo) {
      return {
        tbl_id: selectedBoxId,
        digi_theme_uuid: digiThemeUuid,
      };
    } else {
      const orderObject = dataFromOrderinfo.orderobject.find(
        (orderObject) => orderObject.id === selectedBoxId
      );
      return {
        tbl_id: orderObject?.tbl_id,
        digi_theme_uuid: orderObject?.digi_theme_uuid,
      };
    }
  }, [dataFromOrderinfo, digiThemeUuid, selectedBoxId]);

  const selectedFeature = useCallback(async () => {
    try {
      const response = await fetch(getVectorLayerFeatureUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          gm_session_id: gm_session_id,
        },
        body: JSON.stringify(getVectorLayerFeatureRequest),
      });
      setMapBoxData(await response.json());
      if (!response.ok) {
        throw new Error("Failed to fetch layer feature");
      }
    } catch (error) {
      console.error(error);
    }
  }, [getVectorLayerFeatureRequest, getVectorLayerFeatureUrl, gm_session_id]);

  const handleSetTypeIcon = useCallback((status: string) => {
    switch (status) {
      case STATUSES.COMPLETED:
        return "checkmark";
      case STATUSES.ORDERED:
        return "ordered";
      case STATUSES.NOT_COMPLETED:
        return "notCompleted";
      default:
        return "";
    }
  }, []);

  useEffect(() => {
    if (selectedBoxId !== null || selectedBoxData !== undefined)
      selectedFeature();
  }, [selectedBoxId, selectedBoxData, selectedFeature]);

  useEffect(() => {
    window.addEventListener("online", () => setIsOnline(true));
    window.addEventListener("offline", () => setIsOnline(false));

    return () => {
      window.removeEventListener("online", () => setIsOnline(true));
      window.removeEventListener("offline", () => setIsOnline(false));
    };
  }, []);

  return (
    <div className={`map-options-container ${isMobileView ? "mobile" : ""}`}>
      <div
        className={`map-options ${
          selectedBoxId === null ? "disabled" : ""
        } ${className} ${isHidden ? "hidden" : ""}`}
      >
        {selectedBoxData && (
          <div className="map-options-title">
            <div className="map-options-title-icon">
              <Icon name={handleSetTypeIcon(selectedBoxData.status)} />
              <Text text={t(`common.${selectedBoxData.status}`)} size="xxxs" />
            </div>
            <Text
              className="title"
              text={selectedBoxData?.info1}
              size="xxs"
              fontWeight={600}
            />
          </div>
        )}
        {dataFromMap && (
          <div className="map-options-title">
            <Text
              className="title map"
              text={
                dataFromMap?.desc !== ""
                  ? dataFromMap?.desc
                  : dataFromMap?.tbl_id + " " + dataFromMap?.layer_name
              }
              size="xxs"
              fontWeight={600}
            />
          </div>
        )}
        <div className="map-options-buttons">
          {options.map((option, index) => (
            <div
              key={index}
              className={`btn-container ${
                selectedBoxId === null ||
                (!isOnline &&
                  option.value !== MAP_OPTIONS.REGISTER &&
                  option.value !== MAP_OPTIONS.ZOOM_TO)
                  ? "disabled"
                  : ""
              }`}
            >
              <button
                className={`option-btn ${
                  selectedOption === option.value ? "selected" : ""
                }`}
                onClick={() => handleSelectOption(option.value)}
                disabled={
                  selectedBoxId === null ||
                  (!isOnline &&
                    option.value !== MAP_OPTIONS.REGISTER &&
                    option.value !== MAP_OPTIONS.ZOOM_TO)
                }
              >
                <Icon name={option.icon} />
              </button>
              <Text
                text={t(`pages.orderInfo.${option.label}`)}
                size="xxxs"
                fontWeight={selectedOption === option.value ? 600 : 400}
              />
            </div>
          ))}
        </div>
      </div>
      <div className={`option-popup  ${isHidden ? "hidden" : ""}`}>
        {selectedOption === MAP_OPTIONS.REGISTER &&
          selectedBoxId !== null &&
          showRegisterButton && (
            <RegisterObject
              className={className}
              data={dataFromOrderinfo}
              selectedBoxId={selectedBoxId}
              onClose={handleCloseOptionPopup}
              selectedFilter={selectedFilter}
            />
          )}
        {selectedOption === MAP_OPTIONS.PROPERTIES &&
          selectedBoxId !== null && (
            <ObjectProperties
              className={className}
              selectedBoxData={mapBoxData}
              onClose={handleCloseOptionPopup}
            />
          )}
        {selectedOption === MAP_OPTIONS.MESSAGE &&
          (selectedBoxId !== null || isSendingWKT) && (
            <Message
              className={className}
              dataFromMap={dataFromMap}
              digiThemeUuid={digiThemeUuid}
              dataFromOrderinfo={dataFromOrderinfo}
              onClose={handleCloseOptionPopup}
              selectedBoxId={selectedBoxId}
              fetchVectorLayerFeatures={fetchVectorLayerFeatures}
              toggleHideMapOptions={toggleHideMapOptions}
              sendSelectedCodeToParent={sendSelectedCodeToParent}
            />
          )}
        {selectedOption === MAP_OPTIONS.LOG && selectedBoxId !== null && (
          <Log
            className={className}
            diarydetailsRequest={getVectorLayerFeatureRequest}
            orderobjectFkUuid={selectedBoxData?.fk_diary_uuid}
            onClose={handleCloseOptionPopup}
          />
        )}
      </div>
    </div>
  );
};
