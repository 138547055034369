import React from "react";
import "./TaskFilterButton.scss";

interface TaskFilterButtonProps {
  number: number;
  text: string;
  selected: boolean;
  onSelect: () => void;
}

const TaskFilterButton = ({
  number,
  text,
  selected,
  onSelect,
}: TaskFilterButtonProps) => {
  return (
    <button
      className={`task-filter-button-button ${selected ? "selected" : ""}`}
      onClick={onSelect}
    >
      <span className="button-text">{text}</span>
      <div className="number-container">{number}</div>
    </button>
  );
};

export default TaskFilterButton;
