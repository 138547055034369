import React from "react";
import { Text } from "../text/Text";

import "./PlainTextButton.scss";

export interface PlainTextButtonProps {
  onClick: () => void;
  text: string;
  className?: string;
  disabled?: boolean;
  bold?: boolean;
}

export const PlainTextButton = ({
  onClick,
  text,
  className,
  disabled,
  bold,
}: PlainTextButtonProps) => {
  return (
    <div className="plain-text-button-container">
      <div
        className={`plain-text-button ${className || ""} ${
          disabled ? "plain-text-button--disabled" : ""
        } `}
        onClick={onClick}
      >
        <Text text={text} size="xxs" fontWeight={bold ? 600 : 400} />
      </div>
    </div>
  );
};
