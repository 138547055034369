export enum KIND_RESPONSE {
  TIMEOUT = "timeout",
  CANNOT_CONNECT = "cannot-connect",
  SERVER = "server",
  UNAUTHORIZED = "unauthorized",
  FORBIDDEN = "forbidden",
  NOT_FOUND = "not-found",
  REJECTED = "rejected",
  UNKNOWN = "unknown",
  BAD_DATA = "bad-data",
  OK = "ok",
}

export type GeneralApiProblem =
  | { kind: KIND_RESPONSE.TIMEOUT; temporary: true }
  | { kind: KIND_RESPONSE.CANNOT_CONNECT; temporary: true }
  | { kind: KIND_RESPONSE.SERVER }
  | { kind: KIND_RESPONSE.UNAUTHORIZED }
  | { kind: KIND_RESPONSE.FORBIDDEN }
  | { kind: KIND_RESPONSE.NOT_FOUND }
  | { kind: KIND_RESPONSE.REJECTED }
  | { kind: KIND_RESPONSE.UNKNOWN; temporary: true }
  | { kind: KIND_RESPONSE.BAD_DATA };

export async function getGeneralApiProblem(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  err: any
): Promise<GeneralApiProblem | null> {
  if (err.timeout) {
    return { kind: KIND_RESPONSE.TIMEOUT, temporary: true };
  } else if (err.code === "ECONNABORTED" || err.code === "ENOTFOUND") {
    return { kind: KIND_RESPONSE.CANNOT_CONNECT, temporary: true };
  } else if (err.status >= 500) {
    return { kind: KIND_RESPONSE.SERVER };
  } else if (err.status === 401) {
    return { kind: KIND_RESPONSE.UNAUTHORIZED };
  } else if (err.status === 403) {
    return { kind: KIND_RESPONSE.FORBIDDEN };
  } else if (err.status === 404) {
    return { kind: KIND_RESPONSE.NOT_FOUND };
  } else if (err.status >= 400) {
    return { kind: KIND_RESPONSE.REJECTED };
  } else {
    return { kind: KIND_RESPONSE.UNKNOWN, temporary: true };
  }
}
