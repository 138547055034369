import AdaptiveUiTheme from "@avinet/adaptive-ui-core/adaptive-ui/AdaptiveUiTheme";

import { icons } from "./assets/icons";
import { currentApi } from "./utils/customer-config";

const themes: { [key: string]: AdaptiveUiTheme } = {
  NGIR: {
    name: "ngir-order",
    defaultTextColor: "#475467",
    headingTextColor: "#ffffff",
    headingFontWeight: 500,
    headingFontSizes: ["4.8rem", "4rem", "3rem", "2.4rem", "2rem", "1.6rem"],
    primary: "#285C3E",
    primaryContrast: "#4B9365",
    secondary: "#5157BD",
    secondaryContrast: "#393D7A",
    tertiary: "D9D9D9",
    tertiaryContrast: "#F1F2FC",
    light: "#DCDFEA",
    medium: "#A9D8BF",
    mediumContrast: "#FFFFFF",
    dark: "#121926",
    success: "#039855",
    warning: "#F79009",
    danger: "#F04438",
    radius: 8,
    buttonPadding: "12px 12px",
    inputShadowBlur: 0,
    inputShadowOffset: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    inputBackground: "#ffffff",
    inputTextColor: "#5D6B98",
    inputBorderColor: "#B9C0D4",
    inputBorderColorSingle: "#EBEDFB",
    inputPadding: "10px 14px",
    inputRadius: 8,
    labelTextColor: "#404968",
    labelFontWeight: 500,
    icons,
    checkboxRadius: 4,
    lightContrast: "#EAECF0",
    darkContrast: "#101828",
    warningContrast: "#121926",
    successContrast: "#ECFDF3",
    linkTextColor: "#d7d8f7",
    fontStack: "Inter",
    headingFontStack: "Inter",
  },
  // Add more themes here
};

export const getTheme = (): AdaptiveUiTheme => {
  const { customer } = currentApi();
  return themes[customer] || themes["NGIR"]; // Default to NGIR theme if no match
};

export default getTheme;
