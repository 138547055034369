import { SVGProps } from "react";

export const CheckmarkIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="40" height="40" rx="20" fill="#DCFAE6" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.4942 12.3167L16.5608 23.8334L13.3942 20.45C12.8108 19.9 11.8942 19.8667 11.2275 20.3334C10.5775 20.8167 10.3942 21.6667 10.7942 22.35L14.5442 28.45C14.9108 29.0167 15.5442 29.3667 16.2608 29.3667C16.9442 29.3667 17.5942 29.0167 17.9608 28.45C18.5608 27.6667 30.0108 14.0167 30.0108 14.0167C31.5108 12.4834 29.6942 11.1334 28.4942 12.3V12.3167Z"
        fill="#17B26A"
      />
    </svg>
  );
};
