import superagent from "superagent";

import { DEFAULT_API_CONFIG } from "../api/api-config";
import { getGeneralApiProblem } from "../api/api-problem";
import { Api } from "./api/api";

export class UserApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getUserData(gm_session_id: string) {
    try {
      const response = await superagent
        .get(DEFAULT_API_CONFIG.url + "/Profile/me")
        .set("gm_session_id", gm_session_id)
        .set("Content-Type", "application/json");

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return response.body;
    } catch (error) {
      console.log(`Request error: ${error}`);
    }
  }
}
