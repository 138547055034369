import React from "react";
import { Text } from "../../text/Text";

import "./LogDetails.scss";
import { t } from "i18next";

interface LogDetailsProps {
  code: string;
  codedesc: string;
  id: number;
  val: string;
}

interface DetailsProps {
  details: LogDetailsProps[];
}

const LogDetails = ({ details }: DetailsProps) => {
  return (
    <div className="log-details">
      <Text text={t("pages.log.details")} size="xs" fontWeight={600} />
      {details.length > 0 ? (
        details.map((detail) => (
          <div key={detail.id} className="log-details-row">
            <div className="log-details-column">
              <Text text={detail.codedesc} size="xxxs" />
            </div>
            <div className="log-details-column">
              <Text text={detail.val} size="xxxs" />
            </div>
          </div>
        ))
      ) : (
        <div className="log-details-column">
          <Text text={"-"} size="xxxs" />
        </div>
      )}
    </div>
  );
};

export default LogDetails;
