import { SVGProps } from "react";

export const CrossIcon = (props?: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.89705 6.05379L5.96967 5.96967C6.23594 5.7034 6.6526 5.6792 6.94621 5.89705L7.03033 5.96967L12 10.939L16.9697 5.96967C17.2359 5.7034 17.6526 5.6792 17.9462 5.89705L18.0303 5.96967C18.2966 6.23594 18.3208 6.6526 18.1029 6.94621L18.0303 7.03033L13.061 12L18.0303 16.9697C18.2966 17.2359 18.3208 17.6526 18.1029 17.9462L18.0303 18.0303C17.7641 18.2966 17.3474 18.3208 17.0538 18.1029L16.9697 18.0303L12 13.061L7.03033 18.0303C6.76406 18.2966 6.3474 18.3208 6.05379 18.1029L5.96967 18.0303C5.7034 17.7641 5.6792 17.3474 5.89705 17.0538L5.96967 16.9697L10.939 12L5.96967 7.03033C5.7034 6.76406 5.6792 6.3474 5.89705 6.05379L5.96967 5.96967L5.89705 6.05379Z"
        fill="currentColor"
      />
    </svg>
  );
};
