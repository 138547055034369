import React from "react";
import "./ProgressBar.scss";

interface ProgressBarProps {
  progress: number; // The progress percentage (0 - 100)
}

const ProgressBar = ({ progress }: ProgressBarProps) => {
  return (
    <div className="progress-bar-container">
      <div
        className="progress-bar-fill"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};

export default ProgressBar;
