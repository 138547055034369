import { t } from "i18next";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { NgirLogoIcon } from "../../assets/icons/NgirLogoIcon";
import { Text } from "../../components/text/Text";
import LOCAL_STORAGE from "../../constants/LocalStorage";
import { UserContext } from "../../context/user-context/UserContext";
import "./Login.scss";
import { ordersOverviewUrl, resetPasswordUrl } from "../../constants/Routes";
import { PlainTextButton } from "../../components/buttons/PlainTextButton";

export const Login = () => {
  const { loginUser, isLogin } = useContext(UserContext);
  const [email, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (isLogin) {
      navigate(ordersOverviewUrl);
    }
  }, [isLogin, navigate]);

  const handleForgotPassword = () => {
    localStorage.removeItem(LOCAL_STORAGE.RESET_PASSWORD_EMAIL);
    localStorage.removeItem(LOCAL_STORAGE.RESET_PASSWORD_TOKEN);
    navigate(resetPasswordUrl);
  };

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setShowError(false);
    await loginUser(email, password, setShowError);
  };

  return (
    <div className="login">
      <div className="login__form">
        <form onSubmit={handleLogin}>
          <NgirLogoIcon />
          <Text
            className="login__form__header"
            text={t("pages.login.title")}
            color="dark"
            size="medium"
            fontWeight={600}
          />
          <Text
            className="login__form__welcome"
            text={t("pages.login.welcome")}
            color="dark-contrast"
            size="small"
            fontWeight={400}
          />
          <Text
            className="label"
            text={t("pages.login.email")}
            color="dark-contrast"
            size="xs"
            fontWeight={500}
          />
          <input
            type="text"
            placeholder={t("pages.login.emailPlaceholder") as string}
            value={email}
            onChange={(e) => setUsername(e.target.value)}
          />
          <Text
            className="label"
            text={t("pages.login.password")}
            color="dark-contrast"
            size="xs"
            fontWeight={500}
          />
          <input
            type="password"
            placeholder={t("pages.login.passwordPlaceholder") as string}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Text
            className={`login__form__error ${!showError ? "hidden" : ""}`}
            text={t("pages.login.error")}
            size="xxs"
          />
          <div className="text-btn-container">
            <PlainTextButton
              className="forgot-password"
              onClick={handleForgotPassword}
              text={t("pages.login.forgotPassword")}
            />
          </div>
          <button className="btn" type="submit" onClick={handleLogin}>
            {t("pages.login.login")}
          </button>
        </form>
      </div>
    </div>
  );
};
