import { SVGProps } from "react";

export const SearchIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className="search-icon"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.25 3C6.25482 3 3 6.25482 3 10.25C3 14.2452 6.25482 17.5 10.25 17.5C11.9782 17.5 13.5669 16.8895 14.8145 15.875L19.7197 20.7803C19.7888 20.8523 19.8716 20.9097 19.9632 20.9493C20.0548 20.9889 20.1534 21.0098 20.2532 21.0108C20.3529 21.0118 20.4519 20.9929 20.5443 20.9552C20.6367 20.9175 20.7206 20.8617 20.7912 20.7912C20.8617 20.7206 20.9175 20.6367 20.9552 20.5443C20.9929 20.4519 21.0118 20.3529 21.0108 20.2532C21.0098 20.1534 20.9889 20.0548 20.9493 19.9632C20.9097 19.8716 20.8523 19.7888 20.7803 19.7197L15.875 14.8145C16.8895 13.5669 17.5 11.9782 17.5 10.25C17.5 6.25482 14.2452 3 10.25 3ZM10.25 4.5C13.4345 4.5 16 7.06548 16 10.25C16 11.8013 15.3881 13.2029 14.3955 14.2354C14.3343 14.2803 14.2803 14.3343 14.2354 14.3955C13.2029 15.3881 11.8013 16 10.25 16C7.06548 16 4.5 13.4345 4.5 10.25C4.5 7.06548 7.06548 4.5 10.25 4.5Z"
        fill="#333333"
      />
    </svg>
  );
};
