import React, { useCallback, useEffect, useMemo, useState } from "react";
import PageLayout from "../../components/pagelayout/PageLayout";
import { Text } from "../../components/text/Text";
import "./Orders.scss";
import OrderList from "../order-list/OrderList";
import { t } from "i18next";
import { getResponse, setResponse } from "../../utils/indexedDb";
import { DEFAULT_API_CONFIG } from "../../api/api-config";
import useDataFetch from "../../hooks/useDataFetch";
import { ILayer, IWmsLayer } from "../../utils/data-types";
import LOCAL_STORAGE from "../../constants/LocalStorage";

export const Orders = () => {
  const getActiveLayersNearbyUrl = useMemo<string>(() => {
    return DEFAULT_API_CONFIG.url + "/diaryorder/feature/getActiveLayersNearby";
  }, []);

  const getActiveWmsLayersUrl = useMemo<string>(() => {
    return DEFAULT_API_CONFIG.url + "/diaryorder/feature/getActiveWmsLayers";
  }, []);

  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);
  const [activeLayers] = useDataFetch<ILayer[]>(
    getActiveLayersNearbyUrl,
    "GET"
  );

  const [activeWmsLayers] = useDataFetch<IWmsLayer[]>(
    getActiveWmsLayersUrl,
    "GET"
  );

  useEffect(() => {
    if (activeWmsLayers) {
      localStorage.setItem(
        LOCAL_STORAGE.MAP_LAYERS_WITH_URI,
        JSON.stringify(activeWmsLayers)
      );
    }
  }, [activeWmsLayers]);

  useEffect(() => {
    if (activeLayers) {
      localStorage.setItem(
        LOCAL_STORAGE.MAP_LAYERS_WITH_UUID,
        JSON.stringify(activeLayers)
      );
    }
  }, [activeLayers]);

  const addFailedRequestsDB = useCallback(async () => {
    try {
      const failedRegRequests = await getResponse("failedRegRequests");
      if (failedRegRequests === undefined) {
        await setResponse("failedRegRequests", []);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        await addFailedRequestsDB();
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [addFailedRequestsDB]);

  useEffect(() => {
    window.addEventListener("online", () => setIsOnline(true));
    window.addEventListener("offline", () => setIsOnline(false));

    return () => {
      window.removeEventListener("online", () => setIsOnline(true));
      window.removeEventListener("offline", () => setIsOnline(false));
    };
  }, []);

  return (
    <PageLayout>
      <div className="orders">
        <Text
          className="orders-title"
          text={t("pages.orders.title")}
          size="xxs"
          fontWeight={600}
        />
        <div className="orders-list">
          <OrderList />
        </div>
        {!isOnline && (
          <Text
            text={t("pages.orders.offlineText")}
            size="xxs"
            className="offline-text"
          />
        )}
      </div>
    </PageLayout>
  );
};
