export const map_layers = [
  {
    value: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
    label: "Open Street Map",
  },
  {
    value:
      "https://cache.kartverket.no/v1/wmts/1.0.0/topo/default/webmercator/{z}/{y}/{x}.png",
    label: "Topologisk Farge",
  },
  {
    value:
      "https://cache.kartverket.no/v1/wmts/1.0.0/topograatone/default/webmercator/{z}/{y}/{x}.png",
    label: "Topografisk Gråtone",
  },
];
