import { useContext } from "react";

import { LoadingMask } from "../components/loading/LoadingMask";
import LOCAL_STORAGE from "../constants/LocalStorage";
import { UserContext } from "../context/user-context/UserContext";
import { Login } from "../pages/login/Login";

export function ProtectedRoute({ children }: { children: JSX.Element }) {
  const { isLogin, logoutUser } = useContext(UserContext);

  if (isLogin === undefined) {
    return <LoadingMask />;
  }

  const user_store = localStorage.getItem(LOCAL_STORAGE.USER);

  const userObject = JSON.parse(user_store ?? "{}");

  const expiration_time = userObject.expiration_time;

  if (user_store && expiration_time < new Date().getTime() / 1000) {
    logoutUser();
  }

  if (!isLogin) {
    return <Login />;
  }

  return children;
}
