import { SVGProps } from "react";

export const OrderedIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="12" cy="12" r="12" fill="#D1E0FF" />
      <path
        d="M11.6173 5.88006C12.0252 5.11512 13.1147 5.11517 13.5226 5.88015L14.9586 8.57382L14.0714 8.27604C13.7315 8.16196 13.3641 8.34691 13.2508 8.68915C13.1375 9.03139 13.3212 9.4013 13.6611 9.51538L16.256 10.3863C16.436 10.4467 16.6333 10.4248 16.7958 10.3262C16.9584 10.2277 17.0699 10.0625 17.1011 9.87402L17.5336 7.26124C17.5925 6.9054 17.3538 6.56886 17.0003 6.50955C16.6469 6.45025 16.3127 6.69063 16.2538 7.04648L16.1029 7.95795L14.6657 5.26228C13.7685 3.57933 11.3716 3.57922 10.4742 5.26209L8.97341 8.07647C8.80046 8.40079 8.92772 8.80488 9.25468 8.9696C9.56859 9.12775 9.95049 9.00582 10.1165 8.69444L11.6173 5.88006ZM18.5706 15.3488L16.9174 12.2479C16.7445 11.9235 16.8719 11.5194 17.1989 11.3548C17.5128 11.1967 17.8946 11.3187 18.0606 11.6301L19.7138 14.731C20.5645 16.3265 19.4165 18.2588 17.618 18.2588H14.5681L15.19 18.8849C15.4433 19.14 15.4433 19.5536 15.19 19.8087C14.9366 20.0638 14.5259 20.0638 14.2725 19.8087L12.5425 18.0668C12.2892 17.8117 12.2892 17.3982 12.5425 17.1431L14.2725 15.4012C14.5259 15.1461 14.9366 15.1461 15.19 15.4012C15.4433 15.6563 15.4433 16.0699 15.19 16.325L14.5668 16.9524H17.618C18.4355 16.9524 18.9573 16.0741 18.5706 15.3488ZM10.1899 16.9524C10.5482 16.9524 10.8386 17.2448 10.8386 17.6056C10.8386 17.9663 10.5482 18.2588 10.1899 18.2588H7.52072C5.72207 18.2588 4.57415 16.3263 5.42499 14.7308L7.02203 11.7359L5.8542 12.1281C5.51432 12.2423 5.14689 12.0574 5.03351 11.7152C4.92014 11.373 5.10375 11.003 5.44363 10.8889L8.03858 10.0173C8.20182 9.96246 8.38002 9.97518 8.53394 10.0527C8.68787 10.1301 8.80491 10.266 8.85932 10.4304L9.7243 13.0438C9.83758 13.3861 9.65386 13.756 9.31395 13.87C8.97404 13.9841 8.60666 13.7991 8.49338 13.4568L8.14242 12.3965L6.56811 15.3488C6.18137 16.074 6.70315 16.9524 7.52072 16.9524H10.1899Z"
        fill="#2970FF"
      />
    </svg>
  );
};
