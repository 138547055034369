import { createContext, useContext, useState, ReactNode } from "react";
import Point from "ol/geom/Point";
import LineString from "ol/geom/LineString";
import { DRAWING_OBJECT_TYPES } from "../../constants/Constants";

interface DrawingContextType {
  isDrawMode: boolean;
  setIsDrawMode: (isDrawing: boolean) => void;
  isMovingPoint: boolean;
  setIsMovingPoint: (isMovingPoint: boolean) => void;
  hasDrawn: boolean;
  setHasDrawn: (hasDrawn: boolean) => void;
  drawnObject: Point | LineString | null;
  setDrawnObject: (drawnObjects: Point | LineString | null) => void;
  isSendingWKT: boolean;
  setIsSendingWKT: (isSendingWKT: boolean) => void;
  drawnObjectType: DRAWING_OBJECT_TYPES | null;
  setDrawnObjectType: (drawnObjectType: DRAWING_OBJECT_TYPES | null) => void;
}

const DrawingContext = createContext<DrawingContextType | undefined>(undefined);

interface DrawingProviderProps {
  children: ReactNode;
}

export const DrawingProvider = ({ children }: DrawingProviderProps) => {
  const [isDrawMode, setIsDrawMode] = useState<boolean>(false);
  const [isMovingPoint, setIsMovingPoint] = useState<boolean>(false);
  const [hasDrawn, setHasDrawn] = useState<boolean>(false);
  const [drawnObject, setDrawnObject] = useState<Point | LineString | null>(
    null
  );
  const [isSendingWKT, setIsSendingWKT] = useState<boolean>(false);
  const [drawnObjectType, setDrawnObjectType] =
    useState<DRAWING_OBJECT_TYPES | null>(null);

  return (
    <DrawingContext.Provider
      value={{
        isDrawMode,
        setIsDrawMode,
        isMovingPoint,
        setIsMovingPoint,
        hasDrawn,
        setHasDrawn,
        drawnObject,
        setDrawnObject,
        isSendingWKT,
        setIsSendingWKT,
        drawnObjectType,
        setDrawnObjectType,
      }}
    >
      {children}
    </DrawingContext.Provider>
  );
};

export const useDrawing = () => {
  const context = useContext(DrawingContext);

  if (context === undefined) {
    throw new Error("useDrawing must be used within a DrawingProvider");
  }

  return context;
};
