import { Api } from "../services/api/api";

export class Environment {
  api: Api;
  constructor() {
    this.api = new Api();
  }

  addAuthToken(token?: string | null) {
    this.api.setAuthToken(token);
  }

  async setup() {
    await this.api.setup();
  }
}

export const setupEnvironment = async () => {
  const env = new Environment();
  await env.setup();
  return env;
};
