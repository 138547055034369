import React from "react";
import "./FlipButtons.scss";
import { Icon } from "../icon/Icon";
import { t } from "i18next";
import { useDrawing } from "../../context/drawing-context/DrawingContext";

const FlipButtons = ({
  hasDrawn,
  onCancel,
  onDone,
}: {
  hasDrawn: boolean;
  onCancel: () => void;
  onDone: () => void;
}) => {
  const { drawnObjectType } = useDrawing();
  const drawModeIcon = drawnObjectType === "line" ? "line" : "mapDestination";
  const drawModeText =
    drawnObjectType === "line"
      ? t("pages.map.drawLine")
      : t("pages.map.setPoint");

  return (
    <div className="flip-buttons">
      <button className="btn flip-button left-button">
        <Icon name={drawModeIcon} />
        {drawModeText}
      </button>
      <button
        className="btn flip-button middle-button"
        onClick={onDone}
        disabled={!hasDrawn}
      >
        <Icon name="checkmarkFluent" />
        {t("pages.map.done")}
      </button>
      <button className="btn flip-button right-button" onClick={onCancel}>
        <Icon name="cross" />
        {t("pages.map.cancel")}
      </button>
    </div>
  );
};

export default FlipButtons;
