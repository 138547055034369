import React, { useCallback, useMemo } from "react";
import "./DetailsFilter.scss";
import { t } from "i18next";
import useDataFetch from "../../hooks/useDataFetch";
import { DEFAULT_API_CONFIG } from "../../api/api-config";
import { IFilter } from "../../utils/data-types";
import { Dropdown } from "../dropdown/Dropdown";
import Icon from "@avinet/adaptive-ui-core/ui/Icon";
import { STATUSES } from "../../constants/Constants";

interface DetailsFilterProps {
  selectedOptions: { [key: string]: string | number | undefined };
  onOptionChange: (key: string, value: string | number | undefined) => void;
  showOwnerResponsiblePriorityFilter?: boolean;
}

const DetailsFilter = ({
  selectedOptions,
  onOptionChange,
  showOwnerResponsiblePriorityFilter,
}: DetailsFilterProps) => {
  const statusOptions = useMemo(() => {
    return selectedOptions.status !== undefined
      ? [
          { value: "", label: t("pages.orders.reset") },
          { value: STATUSES.COMPLETED, label: STATUSES.COMPLETED },
          { value: STATUSES.STARTED, label: STATUSES.STARTED },
          { value: STATUSES.ORDERED, label: STATUSES.ORDERED },
        ]
      : [
          { value: STATUSES.COMPLETED, label: STATUSES.COMPLETED },
          { value: STATUSES.STARTED, label: STATUSES.STARTED },
          { value: STATUSES.ORDERED, label: STATUSES.ORDERED },
        ];
  }, [selectedOptions.status]);

  const filtersUrl = useMemo(
    () => DEFAULT_API_CONFIG.url + "/diaryorder/codeV2/get",
    []
  );

  const typesRequest = useMemo(
    () => ({
      field_name: "order_type",
    }),
    []
  );

  const [types] = useDataFetch<IFilter[]>(filtersUrl, "POST", typesRequest);

  const typeOptions = useMemo(() => {
    let options =
      types?.map((type) => ({
        value: type.code,
        label: type.codedesc,
      })) || [];

    if (types && types.length > 0 && selectedOptions.type) {
      options = [{ value: "", label: t("pages.orders.reset") }, ...options];
    }

    return options;
  }, [types, selectedOptions.type]);

  const responsiblesRequest = useMemo(
    () => ({
      field_name: "order_responsible",
    }),
    []
  );

  const [responsibles] = useDataFetch<IFilter[]>(
    filtersUrl,
    "POST",
    responsiblesRequest
  );

  const responsibleOptions = useMemo(() => {
    let options =
      responsibles?.map((responsible) => ({
        value: responsible.code,
        label: responsible.codedesc,
      })) || [];

    if (
      responsibles &&
      responsibles.length > 0 &&
      selectedOptions.responsible
    ) {
      options = [{ value: "", label: t("pages.orders.reset") }, ...options];
    }

    return options;
  }, [responsibles, selectedOptions.responsible]);

  const ownerRequest = useMemo(
    () => ({
      field_name: "order_owner",
    }),
    []
  );

  const [owners] = useDataFetch<IFilter[]>(filtersUrl, "POST", ownerRequest);

  const ownersOptions = useMemo(() => {
    let options =
      owners?.map((owner) => ({
        value: owner.code,
        label: owner.codedesc,
      })) || [];

    if (owners && owners.length > 0 && selectedOptions.owner) {
      options = [{ value: "", label: t("pages.orders.reset") }, ...options];
    }

    return options;
  }, [owners, selectedOptions.owner]);

  const handleClearFilters = useCallback(() => {
    onOptionChange("status", "");
    onOptionChange("route", "");
    onOptionChange("week", 0);
    onOptionChange("year", 0);
    onOptionChange("type", "");
    onOptionChange("priority", "");
    onOptionChange("responsible", "");
    onOptionChange("owner", "");
  }, [onOptionChange]);

  const isClearedFilters = useMemo(() => {
    return (
      !selectedOptions.status &&
      !selectedOptions.route &&
      !selectedOptions.week &&
      !selectedOptions.year &&
      !selectedOptions.type &&
      !selectedOptions.priority &&
      !selectedOptions.responsible &&
      !selectedOptions.owner
    );
  }, [selectedOptions]);

  return (
    <div className="details-filter-container">
      <div className="row">
        <div className="dropdown-wrapper">
          <Dropdown
            title={t("pages.orders.status")}
            options={statusOptions}
            selectedValue={selectedOptions.status}
            placeholder={t("pages.orders.chooseStatus")}
            onSelectChange={(value) => onOptionChange("status", value)}
          />
        </div>
        <div className="dropdown-wrapper">
          <Dropdown
            title={t("pages.orders.route")}
            selectedValue={selectedOptions.route}
            placeholder={t("pages.orders.chooseRoute")}
            onSelectChange={(value) => onOptionChange("route", value)}
          />
        </div>
        <div className="dropdown-wrapper">
          <Dropdown
            title={t("pages.orders.week")}
            type="number"
            selectedValue={selectedOptions.week}
            placeholder={t("pages.orders.chooseWeek")}
            onSelectChange={(value) => onOptionChange("week", value)}
          />
        </div>
        <div className="dropdown-wrapper">
          <Dropdown
            title={t("pages.orders.year")}
            selectedValue={selectedOptions.year}
            placeholder={t("pages.orders.chooseYear")}
            onSelectChange={(value) => onOptionChange("year", value)}
          />
        </div>
      </div>

      <div className="row">
        <div className="dropdown-wrapper">
          <Dropdown
            title={t("pages.orders.type")}
            options={typeOptions}
            selectedValue={selectedOptions.type}
            placeholder={t("pages.orders.chooseType")}
            onSelectChange={(value) => onOptionChange("type", value)}
          />
        </div>
        {showOwnerResponsiblePriorityFilter && (
          <>
            <div className="dropdown-wrapper">
              <Dropdown
                title={t("pages.orders.priority")}
                selectedValue={selectedOptions.priority}
                placeholder={t("pages.orders.choosePriority")}
                onSelectChange={(value) => onOptionChange("priority", value)}
              />
            </div>
            <div className="dropdown-wrapper">
              <Dropdown
                title={t("pages.orders.responsible")}
                options={responsibleOptions}
                selectedValue={selectedOptions.responsible}
                placeholder={t("pages.orders.chooseResponsible")}
                onSelectChange={(value) => onOptionChange("responsible", value)}
              />
            </div>
            <div className="dropdown-wrapper">
              <Dropdown
                title={t("pages.orders.owner")}
                options={ownersOptions}
                selectedValue={selectedOptions.owner}
                placeholder={t("pages.orders.chooseOwner")}
                onSelectChange={(value) => onOptionChange("owner", value)}
              />
            </div>
          </>
        )}

        <div className="reset-filter-btn">
          {!isClearedFilters && (
            <button className="btn-light" onClick={handleClearFilters}>
              <Icon name="dismiss" />
              {t("pages.orders.resetFilter")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default DetailsFilter;
