import { createContext, ReactNode, useContext, useState } from "react";
import { IOrder } from "../../utils/data-types";

interface OrderContextType {
  currentOrder: IOrder | null;
  saveOrder: (order: IOrder | null) => void;
}

const OrderContext = createContext<OrderContextType | undefined>(undefined);

interface OrderProviderProps {
  children: ReactNode;
}

// Create the provider component
export const OrderProvider = ({ children }: OrderProviderProps) => {
  const [currentOrder, setCurrentOrder] = useState<IOrder | null>(null);

  const saveOrder = (order: IOrder | null) => setCurrentOrder(order);

  return (
    <OrderContext.Provider value={{ currentOrder, saveOrder }}>
      {children}
    </OrderContext.Provider>
  );
};

// Custom hook to use the OrderContext
export const useOrder = () => {
  const context = useContext(OrderContext);

  // Ensure the context is not undefined before using it
  if (context === undefined) {
    throw new Error("useOrder must be used within an OrderProvider");
  }

  return context;
};
