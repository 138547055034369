import React, { useCallback } from "react";
import { Text } from "../text/Text";
import "./ObjectProperties.scss";
import { t } from "i18next";
import { Icon } from "../icon/Icon";

const ObjectProperties = ({
  className,
  selectedBoxData,
  onClose,
}: {
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedBoxData: any;
  onClose: () => void;
}) => {
  const capitalizeFirstLetter = useCallback((key: string) => {
    return key.charAt(0).toUpperCase() + key.slice(1);
  }, []);

  return (
    <div className={`object-properties ${className}`}>
      <div className="object-properties-header">
        <Text
          className="object-properties-header-title"
          text={t("pages.properties.title")}
          size="xxs"
          fontWeight={600}
        />
        <div className="object-properties-header-close-btn" onClick={onClose}>
          <Icon name="cross" />
        </div>
      </div>
      {selectedBoxData &&
        selectedBoxData.map((item: string, index: number) => (
          <div className="object-properties-column" key={index}>
            {Object.entries(item).map(
              ([key, value]) =>
                value !== "" &&
                value !== null && (
                  <div className="object-properties-row" key={key}>
                    <Text
                      className="object-key"
                      text={`${capitalizeFirstLetter(key)}:`}
                      size="xxs"
                      fontWeight={600}
                    />

                    <Text
                      className="object-value"
                      text={value}
                      size="xxs"
                      fontWeight={400}
                    />
                  </div>
                )
            )}
          </div>
        ))}
    </div>
  );
};

export default ObjectProperties;
