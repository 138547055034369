import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

interface MobileViewContextType {
  isMobileView: boolean;
  setIsMobileView: (isMobileView: boolean) => void;
  isFullscreenMap: boolean;
  setIsFullscreenMap: (isFullscreenMap: boolean) => void;
  onToggleFullscreenMap: () => void;
}

const MobileViewContext = createContext<MobileViewContextType | undefined>(
  undefined
);

interface MobileViewProviderProps {
  children: ReactNode;
}

// Create the provider component
export const MobileViewProvider = ({ children }: MobileViewProviderProps) => {
  const [isMobileView, setIsMobileView] = useState<boolean>(false);
  const [isFullscreenMap, setIsFullscreenMap] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 900) {
        setIsMobileView(true);
        setIsFullscreenMap(false);
      } else {
        setIsMobileView(false);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onToggleFullscreenMap = useCallback(() => {
    setIsFullscreenMap((prev) => !prev);
  }, []);

  return (
    <MobileViewContext.Provider
      value={{
        isMobileView,
        setIsMobileView,
        isFullscreenMap,
        setIsFullscreenMap,
        onToggleFullscreenMap,
      }}
    >
      {children}
    </MobileViewContext.Provider>
  );
};

// Custom hook to use the MobileViewContext
export const useMobileView = () => {
  const context = useContext(MobileViewContext);

  // Ensure the context is not undefined before using it
  if (context === undefined) {
    throw new Error("useMobileView must be used within an MobileViewProvider");
  }

  return context;
};
