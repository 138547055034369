import { CopyClipboardIcon } from "./icons/CopyClipboardIcon";
import { MapDestinationIcon } from "./icons/MapDestinationIcon";
import { SelectedMapDestinationIcon } from "./icons/SelectedMapDestination";
import { NgirLogoIcon } from "./icons/NgirLogoIcon";
import { OpenIcon } from "./icons/OpenIcon";
import { SearchIcon } from "./icons/SearchIcon";
import { CheckmarkIcon } from "./icons/CheckmarkIcon";
import { SelectedCheckmarkIcon } from "./icons/SelectedCheckmarkIcon";
import { CrossIcon } from "./icons/CrossIcon";
import { SelectedCrossIcon } from "./icons/SelectedCrossIcon";
import { NotCompletedIcon } from "./icons/NotCompletedIcon";
import { SelectedNotCompletedIcon } from "./icons/SelectedNotCompletedIcon";
import { OrderedIcon } from "./icons/OrderedIcon";
import { SelectedOrderedIcon } from "./icons/SelectedOrderedIcon";
import { CheckboxCheckedIcon } from "./icons/CheckboxCheckedIcon";
import { CheckboxUncheckedIcon } from "./icons/CheckboxUncheckedIcon";
import { LineIcon } from "./icons/LineIcon";
import {
  ArrowEnterLeft20Regular,
  ArrowForward20Regular,
  Attach24Regular,
  ChatWarning24Regular,
  Checkmark24Regular,
  ClipboardTaskList16Regular,
  CloudArrowDown24Regular,
  Database24Regular,
  Filter24Regular,
  History24Regular,
  Info24Regular,
  Layer24Regular,
  List24Regular,
  MyLocation20Regular,
  ScanCamera24Regular,
  Settings24Regular,
  Status24Regular,
  Warning24Regular,
  ZoomIn24Regular,
  VehicleCarProfileLtrClock24Regular,
} from "@fluentui/react-icons";

export const icons = {
  arrowEnter: <ArrowEnterLeft20Regular />,
  arrowForward: <ArrowForward20Regular />,
  attach: <Attach24Regular />,
  carClock: <VehicleCarProfileLtrClock24Regular />,
  chat: <ChatWarning24Regular />,
  checkmark: <CheckmarkIcon />,
  checkmarkFluent: <Checkmark24Regular />,
  checkboxChecked: <CheckboxCheckedIcon />,
  checkboxUnchecked: <CheckboxUncheckedIcon />,
  clipboardTaskList: <ClipboardTaskList16Regular />,
  cloudArrowDown: <CloudArrowDown24Regular />,
  copyClipboard: <CopyClipboardIcon />,
  cross: <CrossIcon />,
  database: <Database24Regular />,
  filter: <Filter24Regular />,
  history: <History24Regular />,
  info: <Info24Regular />,
  line: <LineIcon />,
  list: <List24Regular />,
  mapDestination: <MapDestinationIcon />,
  mapLayer: <Layer24Regular />,
  myLocation: <MyLocation20Regular />,
  ngirLogo: <NgirLogoIcon />,
  notCompleted: <NotCompletedIcon />,
  open: <OpenIcon />,
  ordered: <OrderedIcon />,
  scanCamera: <ScanCamera24Regular />,
  search: <SearchIcon />,
  selectedCheckmark: <SelectedCheckmarkIcon />,
  selectedCross: <SelectedCrossIcon />,
  selectedMapDestination: <SelectedMapDestinationIcon />,
  selectedNotCompleted: <SelectedNotCompletedIcon />,
  selectedOrdered: <SelectedOrderedIcon />,
  settings: <Settings24Regular />,
  status: <Status24Regular />,
  warning: <Warning24Regular />,
  zoomIn: <ZoomIn24Regular />,
};
