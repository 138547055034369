import { BrowserRouter, Route, Routes } from "react-router-dom";

import {
  baseUrl,
  createNewPasswordUrl,
  enterCodeUrl,
  mapUrl,
  ordersOverviewUrl,
  resetPasswordUrl,
  settingsUrl,
} from "../constants/Routes";
import { ProtectedRoute } from "./ProtectedRoute";
import { UnprotectedRoute } from "./UnprotectedRoute";
import { Orders } from "../pages/orders/Orders";
import { Login } from "../pages/login/Login";
import { EnterCode } from "../pages/enter-code/EnterCode";
import { ResetPassword } from "../pages/reset-password/ResetPassword";
import { CreateNewPassword } from "../pages/create-new-password/CreateNewPassword";
import { Settings } from "../pages/settings/Settings";
import { Map } from "../pages/map/Map";
import OrderInfo from "../components/order-info/OrderInfo";
import { OrderProvider } from "../context/order-context/OrderContext";
import { DrivingProvider } from "../context/driving-context/DrivingContext";
import { OnRegisterProvider } from "../context/on-register-context/OnRegisterContext";
import { DrawingProvider } from "../context/drawing-context/DrawingContext";
import { MobileViewProvider } from "../context/mobile-view-context/MobileViewContext";

export function Router() {
  return (
    <MobileViewProvider>
      <OrderProvider>
        <DrivingProvider>
          <DrawingProvider>
            <OnRegisterProvider>
              <BrowserRouter>
                <Routes>
                  <Route
                    path={baseUrl}
                    element={
                      <UnprotectedRoute>
                        <Login />
                      </UnprotectedRoute>
                    }
                  />
                  <Route
                    path={resetPasswordUrl}
                    element={
                      <UnprotectedRoute>
                        <ResetPassword />
                      </UnprotectedRoute>
                    }
                  />
                  <Route
                    path={enterCodeUrl}
                    element={
                      <UnprotectedRoute>
                        <EnterCode />
                      </UnprotectedRoute>
                    }
                  />
                  <Route
                    path={createNewPasswordUrl}
                    element={
                      <UnprotectedRoute>
                        <CreateNewPassword />
                      </UnprotectedRoute>
                    }
                  />
                  <Route
                    path={ordersOverviewUrl}
                    element={
                      <ProtectedRoute>
                        <Orders />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`${ordersOverviewUrl}/:orderId/:selectedFilter`}
                    element={
                      <ProtectedRoute>
                        <OrderInfo />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={settingsUrl}
                    element={
                      <ProtectedRoute>
                        <Settings />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={mapUrl}
                    element={
                      <ProtectedRoute>
                        <Map />
                      </ProtectedRoute>
                    }
                  />
                </Routes>
              </BrowserRouter>
            </OnRegisterProvider>
          </DrawingProvider>
        </DrivingProvider>
      </OrderProvider>
    </MobileViewProvider>
  );
}
