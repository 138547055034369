import React, { useContext } from "react";
import { Navigate } from "react-router-dom";

import { LoadingMask } from "../components/loading/LoadingMask";
import { baseUrl } from "../constants/Routes";
import { UserContext } from "../context/user-context/UserContext";

export function UnprotectedRoute({
  children,
}: {
  children: JSX.Element;
  redirectPath?: string;
}) {
  const { userProfile, userProfileLoading } = useContext(UserContext);

  if (userProfileLoading) {
    return <LoadingMask />;
  }

  if (userProfile?.uuid) {
    return <Navigate to={baseUrl} replace />;
  }

  return children;
}
