import { SVGProps } from "react";

export const LineIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <path d="M 11 4 A 3 3 0 0 0 8 7 A 3 3 0 0 0 10.896484 9.9921875 L 11.193359 11.330078 L 11.638672 13.333984 A 3 3 0 0 0 10.027344 15.615234 L 9.1855469 15.720703 L 7.7890625 15.896484 A 3 3 0 0 0 5 14 A 3 3 0 0 0 2 17 A 3 3 0 0 0 5 20 A 3 3 0 0 0 7.9726562 17.384766 L 9.3710938 17.208984 L 10.21875 17.103516 A 3 3 0 0 0 13 19 A 3 3 0 0 0 16 16 A 3 3 0 0 0 13.101562 13.001953 L 12.658203 11.005859 L 12.359375 9.6660156 A 3 3 0 0 0 13.419922 8.7617188 L 14.763672 9.265625 L 16.019531 9.7363281 A 3 3 0 0 0 16 10 A 3 3 0 0 0 19 13 A 3 3 0 0 0 22 10 A 3 3 0 0 0 19 7 A 3 3 0 0 0 16.582031 8.2382812 L 15.326172 7.7675781 L 13.980469 7.2636719 A 3 3 0 0 0 14 7 A 3 3 0 0 0 11 4 z M 11 5.5 A 1.5 1.5 0 0 1 12.5 7 A 1.5 1.5 0 0 1 11 8.5 A 1.5 1.5 0 0 1 9.5 7 A 1.5 1.5 0 0 1 11 5.5 z M 19 8.5 A 1.5 1.5 0 0 1 20.5 10 A 1.5 1.5 0 0 1 19 11.5 A 1.5 1.5 0 0 1 17.5 10 A 1.5 1.5 0 0 1 19 8.5 z M 13 14.5 A 1.5 1.5 0 0 1 14.5 16 A 1.5 1.5 0 0 1 13 17.5 A 1.5 1.5 0 0 1 11.5 16 A 1.5 1.5 0 0 1 13 14.5 z M 5 15.5 A 1.5 1.5 0 0 1 6.5 17 A 1.5 1.5 0 0 1 5 18.5 A 1.5 1.5 0 0 1 3.5 17 A 1.5 1.5 0 0 1 5 15.5 z " />
    </svg>
  );
};
