import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";

interface OnRegisterContextType {
  hasRegistered: boolean;
  onRegister: (hasRegistered: boolean) => void;
}

const OnRegisterContext = createContext<OnRegisterContextType | undefined>(
  undefined
);

interface OnRegisterProviderProps {
  children: ReactNode;
}

// Create the provider component
export const OnRegisterProvider = ({ children }: OnRegisterProviderProps) => {
  const [hasRegistered, setHasRegistered] = useState<boolean>(false);

  const onRegister = useCallback(
    (hasRegistered: boolean) => setHasRegistered(hasRegistered),
    []
  );

  return (
    <OnRegisterContext.Provider value={{ hasRegistered, onRegister }}>
      {children}
    </OnRegisterContext.Provider>
  );
};

// Custom hook to use the OnRegisterContext
export const useOnRegister = () => {
  const context = useContext(OnRegisterContext);

  // Ensure the context is not undefined before using it
  if (context === undefined) {
    throw new Error("useOnRegister must be used within an OnRegisterProvider");
  }

  return context;
};
